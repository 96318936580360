import React, { useEffect } from 'react';
import { Progress, Flex, Box, useMediaQuery } from '@chakra-ui/react';
import {
  Backup,
  Charities,
  Executors,
  FormButton,
  Forms,
  Guardians,
  Personal,
  Preview,
  Submit,
  // Witnesses,
} from '../components/Forms';
import { FormAccordion } from '../components/Forms';
import { helperInfo } from '../assets/helperInfo';
import Footer from "../components/Footer";
import Download from './Download';
import { useSearchParams } from 'react-router-dom';

export default function Create() {
  const [step, setStep] = React.useState(1);
  const [progress, setProgress] = React.useState(5);
  const [isTinyMobile] = useMediaQuery(' (max-width: 350px)');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const currentParams = Object.fromEntries([...searchParams]);
    console.log(currentParams); // get new values onchange
    if(currentParams !== undefined && currentParams !== null && searchParams.get('update')) {
      setStep(0)
    }
  }, [searchParams]);

  let stepToolTip =
    step === 1
      ? helperInfo.personal
      : step === 2
      ? helperInfo.executors
      : step === 3
      ? helperInfo.guardians
      : step === 4
      ? helperInfo.charities
      : step === 5
      // ? helperInfo.witnesses
      // : step === 6
      ? helperInfo.backup
      : step === 6
      ? helperInfo.preview
      : null;
  const ref = React.useRef(null);

  return (
    <>
      <div className='mb-10 print:m-0 print:p-0'>
        <Forms className='print:m-0 print:p-0'
          step={step}
          setStep={setStep}
          progress={progress}
          setProgress={setProgress}>
          <Flex className='print:m-0 print:p-0' direction={'column'}>
            <Flex className='flex-col m-0 justify-between p-2 md:p-6 rounded-lg border-[1px] min-h-[85vh] print:py-0 print:px-6 print:m-0'
              // color='tertiary'
              // minH={'85vh'}
              // flexDirection='column'
              // justifyContent='space-between'
              // borderColor={{ color: 'primaryTint' }}
              // borderWidth='1px'
              // m='0'
              // p={isTinyMobile ? '0.5em' : '1.5em'}
              >
              <Box className='max-w-xl w-full mx-auto print:max-w-6xl print:m-0 p-0'>
                <Progress className='mb-[2%] print:m-0 print:hidden mx-[5%]'
                  display={step === 7 ? 'none' : 'block'}
                  value={progress}
                  // mb='2%'
                  // mx='5%'
                  isAnimated>
                </Progress>

                {step === 1 ? (
                  <Personal toolRef={ref} />
                ) : step === 2 ? (
                  <Executors toolRef={ref} />
                ) : step === 3 ? (
                  <Guardians toolRef={ref} />
                ) : step === 4 ? (
                  <Charities toolRef={ref} />
                ) : step === 5 ? (
                //   <Witnesses toolRef={ref} />
                // ) : step === 6 ? (
                  <Backup toolRef={ref} />
                ) : step === 6 ? (
                  // <Submit className='print:hidden' />
                  <Preview className='print:hidden' />
                ) : (
                  <Download className='print:hidden' />
                )}
              </Box>
              <Box ref={ref} className='max-w-xl mx-auto w-full print:hidden print:max-w-none'>
                {stepToolTip && <FormAccordion description={stepToolTip} />}
              </Box>
              <FormButton className='print:hidden'
                step={step}
                setStep={setStep}
                progress={progress}
                setProgress={setProgress}
              />
            </Flex>
          </Flex>
        </Forms>
      </div>
      <Footer />
    </>
  );
}
