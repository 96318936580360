export const formValues = {
  personal: {
    heading: 'Personal Information',
    isSubheading: false,
    data: {
      personal_province: {
        subHeading: 'Province',
        value: '',
      },
      personal_name: {
        subHeading: 'Name',
        value: '',
      },
      personal_address: {
        subHeading: `Address`,
        value: '',
      },
      personal_email: {
        subHeading: `Email`,
        value: '',
      },
    },
  },
  executor: {
    heading: 'Executors',
    isSubheading: true,
    data: {
      main: {
        subHeading: 'Main Executor',
        value: {
          executor_main_name: {
            subHeading: `Name`,
            value: '',
          },
          executor_main_address: {
            subHeading: `Address`,
            value: '',
          },
        },
      },
      backup: {
        subHeading: 'Backup Executor (Optional)',
        value: {
          executor_backup_name: {
            subHeading: `Name`,
            value: '',
          },
          executor_backup_address: {
            subHeading: `Address`,
            value: '',
          },
        },
      },
    },
  },
  guardian: {
    heading: 'Guardians (Optional)',
    isSubheading: true,
    data: {
      main: {
        subHeading: 'Main Guardian',
        value: {
          guardian_main_name: {
            subHeading: `Name`,
            value: '',
          },
          guardian_main_address: {
            subHeading: `Address`,
            value: '',
          },
        },
      },
      backup: {
        subHeading: 'Backup Guardian',
        value: {
          guardian_backup_name: {
            subHeading: `Name`,
            value: '',
          },
          guardian_backup_address: {
            subHeading: `Address`,
            value: '',
          },
        },
      },
    },
  },
  charities: {
    heading: 'Charitable Contributions and Testamentary Transfer (Optional)',
    isSubheading: false,
    data: {
      value: {
        value: ' '
      },
      charities_array: [],
    },
  },
  // witness: {
  //   heading: 'Witnesses',
  //   isSubheading: true,
  //   data: {
  //     1: {
  //       subHeading: 'Witness 1 (Required)',
  //       value: {
  //         witness_1_name: {
  //           subHeading: `Name`,
  //           value: '',
  //         },
  //         witness_1_address: {
  //           subHeading: `Address`,
  //           value: '',
  //         },
  //       },
  //     },
  //     2: {
  //       subHeading: 'Witness 2 (Required)',
  //       value: {
  //         witness_2_name: {
  //           subHeading: `Name`,
  //           value: '',
  //         },
  //         witness_2_address: {
  //           subHeading: `Address`,
  //           value: '',
  //         },
  //       },
  //     },
  //     // 3: {
  //     //   subHeading: 'Witness 3 (Optional)',
  //     //   value: {
  //     //     witness_3_name: {
  //     //       subHeading: `Name`,
  //     //       value: '',
  //     //     },
  //     //     witness_3_address: {
  //     //       subHeading: `Address`,
  //     //       value: '',
  //     //     },
  //     //   },
  //     // },
  //   },
  // },
  backup: {
    heading: 'Backup Beneficiary Charity',
    isSubheading: false,
    data: {
      // backup_type: {
      //   subHeading: 'Type',
      //   value: '',
      // },
      backup_charityPurpose: {
        subHeading: 'Purpose',
        value: '',
      },
      backup_charityName: {
        subHeading: 'Name',
        value: '',
      },
      backup_charityAddress: {
        subHeading: 'Address',
        value: '',
      },
      backup_charityNumber: {
        subHeading: 'Charity Number',
        value: '',
      },
    },
  },
};
