import background from "../assets/pattern-white.svg";

export default function Footer() {
  return(
    <section className="py-10 pb-20 bg-white" style={{backgroundImage: `url(${background})`, backgroundPosition: "center"}}>
      <div className="container px-4 mx-auto">
        <div className="max-w-5xl mx-auto text-center mb-16 md:mb-24">
          <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-yellow-500 bg-yellow-100 font-medium uppercase rounded-full shadow-sm">How it works</span>
          <h2 className="mb-4 text-4xl md:text-5xl leading-tight font-bold tracking-tighter">How does FreeIslamicWill.ca work?</h2>
          <p className="text-lg md:text-xl text-coolGray-500 font-medium">Here are the steps to creating your Islamic will.</p>
        </div>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16">
            <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
              <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                <div className="inline-flex items-center justify-center w-12 h-12 bg-yellow-500 rounded-full text-xl font-semibold text-white">1</div>
              </div>
              <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">Fill in your personal details</h3>
              <p className="text-coolGray-500 font-medium">Start by entering your personal details such as name, date of birth, gender and address.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16">
            <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
              <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                <div className="inline-flex items-center justify-center w-12 h-12 bg-yellow-500 rounded-full text-xl font-semibold text-white">2</div>
              </div>
              <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">Add executors</h3>
              <p className="text-coolGray-500 font-medium">Choose who you would like to appoint as your estate trustee and will executer.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16">
            <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
              <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                <div className="inline-flex items-center justify-center w-12 h-12 bg-yellow-500 rounded-full text-xl font-semibold text-white">3</div>
              </div>
              <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">Include guardians</h3>
              <p className="text-coolGray-500 font-medium">Choose who you would like to appoint as the guardian of your minor children.</p>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16 lg:mb-0">
            <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
              <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                <div className="inline-flex items-center justify-center w-12 h-12 bg-yellow-500 rounded-full text-xl font-semibold text-white">4</div>
              </div>
              <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">Set charitable contributions and testamentary transfer</h3>
              <p className="text-coolGray-500 font-medium">You can allocate your charitable contributions, also known as bequests, by selecting the organization or individual to receive them along with the percentage of your wealth you wish to donate, with a maximum limit of 33.3%.</p>
            </div>
          </div>
          {/* <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-16 md:mb-0">
            <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
              <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                <div className="inline-flex items-center justify-center w-12 h-12 bg-yellow-500 rounded-full text-xl font-semibold text-white">5</div>
              </div>
              <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">Name witnesses</h3>
              <p className="text-coolGray-500 font-medium">Enter your primary and secondary witnesses who will witness your will-signing.</p>
            </div>
          </div> */}
          <div className="w-full md:w-1/2 lg:w-1/3 px-4">
            <div className="relative h-full px-8 pt-14 pb-8 bg-coolGray-50 rounded-md shadow-md">
              <div className="absolute top-0 left-1/2 transform -translate-y-1/2 -translate-x-1/2 inline-flex items-center justify-center h-16 w-16 bg-white rounded-full">
                <div className="inline-flex items-center justify-center w-12 h-12 bg-yellow-500 rounded-full text-xl font-semibold text-white">5</div>
              </div>
              <h3 className="md:w-64 mb-4 text-lg md:text-xl font-bold">Download, print & follow next steps</h3>
              <p className="text-coolGray-500 font-medium">Download the generated will document along with the instructions document. Then, follow the steps outlined in the instructions, which may vary depending on the province you are in.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}