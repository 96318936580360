import React from 'react';
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  chakra,
} from '@chakra-ui/react';
import TextPara from './TextPara';

export default function AccordionBox({
  heading,
  description,
  index,
  headingStyle,
  descriptionStyle,
}) {
  return (
    <AccordionItem border={'none'}>
      <AccordionButton className='rounded-md mb-3 bg-amber-400 text-slate-600'
        // py={'0.2em'}
        // bg={'primary'}
        // _expanded={{ bg: 'primary' }}
        // _hover={{ bg: 'primary' }}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        {...headingStyle}>
        <Text className='text-sm md:text-base'
          as='p'
          pr={'0.5em'}
          // textAlign='justify'
          // color='tertiary'
          // fontSize={'9px'}
          __css={{
            '&:first-letter': {
              textTransform: 'capitalize',
            },
          }}>
          {
            <>
              {index && (
                <chakra.span fontWeight={'bold'}>{`${index}. `}</chakra.span>
              )}
              <chakra.span>{heading.trim()}</chakra.span>
            </>
          }
        </Text>
        {/* <AccordionIcon color={'tertiary'} /> */}
        <AccordionIcon  />
      </AccordionButton>
      <AccordionPanel
        // mt='0.2em'
        // mb='0.5em'
        >
        <TextPara className='text-sm'
          // lineHeight={1.1}
          // fontSize={'8px'}
          {...descriptionStyle}>
          {description}
        </TextPara>
      </AccordionPanel>
    </AccordionItem>
  );
}
