import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { validationSchema } from './validationSchema';
import { useToast } from "@chakra-ui/react"

export default function Forms({
  step,
  setStep,
  progress,
  setProgress,
  style,
  children,
  ...props
}) {
  const toast = useToast()
  const formData =  JSON.parse(localStorage.getItem("formData"));
  const update =  JSON.parse(localStorage.getItem("update"));
  const formCharities =  formData?.charities_array;
  const id = 'warning-toast'
  useEffect(() => {
    if(formData?.personal_province && !toast.isActive(id) && !window.location.href.includes('update')) {
      toast({
        title: update === true ? 'We retrieved your data from your backup file successfully.' : 'Since you did not download the backup of your will, we stored what you wrote in your browser and retrieved it back for you now to continue your will',
        status: update === true ? 'success' : 'warning',
        isClosable: true,
        duration: 30000,
        position: 'top',
        id
      })
      localStorage.removeItem("update");
  }
  }, [])

  return (
    <Formik
      initialValues={{
        personal_province: formData?.personal_province,
        personal_name: formData?.personal_name,
        personal_email: formData?.personal_email,
        personal_address: formData?.personal_address,
        executor_main_name: formData?.executor_main_name,
        executor_main_address: formData?.executor_main_address,
        executor_backup_name: formData?.executor_backup_name,
        executor_backup_address: formData?.executor_backup_address,
        guardian_main_name: formData?.guardian_main_name,
        guardian_main_address: formData?.guardian_main_address,
        guardian_backup_name: formData?.guardian_backup_name,
        guardian_backup_address: formData?.guardian_backup_address,
        charities_array: formCharities !== null && formCharities !== undefined ? formCharities.map((item, index) => ({type: item?.type, name: item?.name, address: item?.address, charityNumber: item?.charityNumber, purpose: item?.purpose , percentage: item?.percentage})) : [{ type: '', name: '', address: '', charityNumber: '', purpose: '', percentage: '' }],
        // witness_1_name: '',
        // witness_1_address: '',
        // witness_2_name: '',
        // witness_2_address: '',
        // witness_3_name: '',
        // witness_3_address: '',
        //backup_type: '',
        backup_charityPurpose: formData?.backup_charityPurpose,
        backup_charityName: formData?.backup_charityName,
        backup_charityAddress: formData?.backup_charityAddress,
        backup_charityNumber: formData?.backup_charityNumber,
      }}
      validationSchema={validationSchema[step - 1]}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values) => {
        if (step <= 6) {
          setStep(step + 1);
          setProgress(step === 6 ? 100 : progress + 19);
        } else if (step === 7) {
          await new Promise((r) => setTimeout(r, 500));
          // alert(JSON.stringify(values, null, 2));
          //window.print();
        }
      }}
      style={style}>
      <Form {...props}>{children}</Form>
    </Formik>
  );
}
