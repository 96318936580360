import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import bismillah from "../../assets/bismi.png";
import sample from './sample.json';

// Font.register(
//   {
//     family: 'Arial Black',
//     fonts: [
//       {
//         // src: 'https://themes.googleusercontent.com/licensed/font?kit=7ElOOAO7mews2kB7feQNqZ0EAVxt0G0biEntp43Qt6E',
//         src: 'https://themes.googleusercontent.com/licensed/font?kit=nlKOmF1wqcz6D96Jy0PsIA',
//       }
//     ],
//   }
// );

//TODO: the lack of .ttf at the end is causing a CORS error: Missing Header
// Font.register(
//   {
//     family: 'Calibri',
//     fonts: [
//       {
//         src: 'https://themes.googleusercontent.com/static/fonts/roboto/v9/zN7GBFwfMP4uA6AR0HCoLQ',
//         //source is https://gist.github.com/dotJoel/7326331
//       }
//     ],
//   }
// );

// Font.register(
//   {
//     family: 'Roboto Regular',
//     fonts: [
//       {
//         src: 'https://themes.googleusercontent.com/static/fonts/roboto/v9/zN7GBFwfMP4uA6AR0HCoLQ.ttf',
//       },
//       {
//         src: 'https://themes.googleusercontent.com/static/fonts/roboto/v9/zN7GBFwfMP4uA6AR0HCoLQ.ttf',
//         fontWeight: 'bold',
//       },
//     ],
//   }
// );

Font.register(
  {
    family: 'bold font',
    fonts: [
      {
        src: 'https://themes.googleusercontent.com/static/fonts/roboto/v9/zN7GBFwfMP4uA6AR0HCoLQ.ttf',
        fontWeight: '1000',
      },
    ],
  }
);

// Create styles
const styles = StyleSheet.create({
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    height: "1024px",
  },

  textHeadingH2: {
    fontSize: 22,
    color: "#000000",
    fontFamily: "Helvetica",
    fontWeight: 'bold',
    marginTop: "20",
    marginBottom: "10",
    alignItems: "center",
    textAlign: "center",
  },

  textHeadingH2NoPadding: {
    fontSize: 22,
    color: "#000000",
    fontFamily: "Helvetica",
    fontWeight: 'bold',
    alignItems: "center",
    textAlign: "center",
  },

  imageView: {
    alignContent: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 207,
    height: 34,
  },

  textContent: {
    fontSize: 12,
    color: "#000000",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    textAlign: "Left",
    lineHeight: 1.5,
    paddingBottom: "30px",
    textIndent: "25px"
  },
  textContentDynamic: {
    fontSize: 12,
    color: "#000000",
    //TODO: Must change this to Calibri-Bold but I haven't yet been able to get the url to that ttf.
    fontFamily: "Helvetica-Bold",
    fontStyle: "normal",
    textAlign: "Left",
    paddingBottom: "10px",
  },
  textBlankLine: {
    fontSize: 12,
    color: "#000000",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    textAlign: "Left",
    lineHeight: 1.5,
    paddingTop: "20px",
  },
  textContentBulletPoint: {
    fontSize: 12,
    color: "#000000",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    textAlign: "Left",
    marginTop: "20px",
    lineHeight: 1.5,
    marginLeft: 10,
  },
  textContentBulletPointLevel2: {
    fontSize: 12,
    color: "#000000",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    textAlign: "Left",
    marginLeft: 25,
    lineHeight: 1.5,
    marginTop: "10px",
  },
  pageNumber: {
    fontSize: 8,
    marginTop: "3",
    bottom: 15,
    left: 0,
    right: 35,
    color: "#000000",
    textAlign: "right",
    fontFamily: "Helvetica",
    position: "absolute",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  tableText: {
    fontSize: 11,
    color: "#000000",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    textAlign: "center",
    paddingBottom: "10px",
  },
  tableTextNoPadding: {
    fontSize: 11,
    color: "#000000",
    fontFamily: "Helvetica",
    fontStyle: "normal",
    textAlign: "center",
    //paddingBottom: "10px",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    marginLeft: 0,
    marginTop: 5,
  },
  tableNoBorder: {
    display: "table",
    width: "auto",
    //borderStyle: "solid",
    borderWidth: 0,
    borderColor: "#000000",
    marginLeft: 0,
    marginTop: 5,
  },
  row: {
    flexDirection: "row",
  },
  cell2ColumnSpanned: {
    width: "66.67%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    padding: 5,
    textAlign: "center",
  },
  cell2Column: {
    width: "50.0%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    padding: 5,
    textAlign: "center",
  },
  cell3Column: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    padding: 5,
    textAlign: "center",
  },
  cell3ColumnNoBorder: {
    width: "33.33%",
    //borderStyle: "solid",
    borderWidth: 0,
    borderColor: "#000000",
    padding: 0,
    textAlign: "center",
  },
  cellId: {
    width: "8.00%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    padding: 5,
    textAlign: "center",
  },
  cellHeir: {
    width: "40.00%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    padding: 5,
    textAlign: "center",
  },
  cellShare: {
    width: "52.00%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    padding: 5,
    textAlign: "center",
  },
  headerCell: {
    backgroundColor: "#bfbfbf",
    fontWeight: "bold",
  },
  

  container: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    color: "#112131",
    marginVertical: 10,
  },
  textHadith: {
    margin: 50,
    fontSize: 20,
    fontStyle: "bold",
    fontWeight: 400,
    textAlign: "justify",
    color: "#333",
  },

  boldText: {
    fontWeight: 'bold',
  },
});

const scheduleA_caseOneTableData = [
  {
    id: '1.a',
    heirs: 'with no other relatives',
    share: 'he, or they, get all the remainder such that sons are equal in their class, daughters are equal in their class, and for a daughter half of a son\'s share.',
  },
  {
    id: '1.b',
    heirs: 'with wife',
    share: '1/8 to wife, the rest as in (1.a)',
  },
  {
    id: '1.c',
    heirs: 'with husband',
    share: '1/4 to husband, the rest as in (1.a)',
  },
  {
    id: '1.d',
    heirs: 'with father and mother',
    share: '1/6 to father and 1/6 to mother, the rest as in (1.a)',
  },
  {
    id: '1.e',
    heirs: 'with one parent',
    share: '1/6 to parent, the rest as in (1.a)',
  },
  {
    id: '1.f',
    heirs: 'with any possible combination of (1.b), (1.c), (1.d), and (1.e)',
    share: 'spouse and parents take shares mentioned above, and the rest as in (1.a)',
  },
  {
    id: '1.g',
    heirs: 'with father of father, no parents and no other grandparents',
    share: '1/6 to father of father and the rest as in (1.a)',
  },
  {
    id: '1.h',
    heirs: 'with father of father and mother of or mother of mother, no parents',
    share: '1/6 to father of father, 1/6 to either father mother of father or mother of mother, the rest as in (1.a)',
  },
  {
    id: '1.i',
    heirs: '(1.g) or (1.h) with wife',
    share: '1/6 to mother of father or of mother (if she exists); 1/6 to father of father, 1/8 to wife, and father of father, the rest as in (1.a)',
  },
  {
    id: '1.j',
    heirs: '(1.g) or (1.h) with husband',
    share: '1/6 to mother of father or of mother (if she exists); 1/6 to father of father; 1/4 to husband; the rest as in (1.a)',
  },
  {
    id:'1.k',
    heirs:'with father of father, and mother, no father',
    share:'1/6 to mother, 1/6 to father of father, the rest as in (1.a)'
 },
 {
    id:'1.l',
    heirs:'1.k with wife',
    share:'1/6 to mother, 1/6 to father of father, 1/8 to wife, and the rest as in (1.a)'
 },
 {
    id:'1.m',
    heirs:'1.k with husband',
    share:'1/6 to mother, 1/6 to father of father, 1/8 to wife, and the rest as in (1.a)'
 },
 {
    id:'1. n',
    heirs:'with father and mother of mother (no mother)',
    share:'1/6 to mother of mother, 1/6 to father, and the rest as in (1.a)'
 },
 {
    id:'1.o',
    heirs:'(1.n) with wife',
    share:'1/6 to father, 1/6 to mother of mother, 1/8 to wife, and the rest as in (1.a)'
 },
 {
    id:'1.p',
    heirs:'(1.n) with husband',
    share:'1/6 to father, 1/6 to mother of mother, 1/4 to husband, and the rest as in (1.a)'
 },
 {
    id:'1.q',
    heirs:'with either mother of father or mother of mother, no parents, and no father of father',
    share:'1/6 to mother of mother or mother of father, the rest as in (1.a)'
 },
 {
    id:'1.r',
    heirs:'(1.q) with wife',
    share:'1/6 to mother of mother or mother of father, 1/8 to wife, the rest as in (1.a)'
 },
 {
    id:'1.s',
    heirs:'(1.q) with husband',
    share:'1/6 to mother of mother or mother of father, 1/4 to husband, the rest as in (1.a)'
 },
 {
    id:'1.t',
    heirs:'(1.h), (1.n), or (1.q), but instead of one grandmother, there are two or more, same degree, grandmothers (i.e mother of mother and mother of father; or mother of mother of mother, mother of mother of father, and mother of father of father, disregard mother of father of mother, and no mother of mother nor mother of father)',
    share:'grandmothers share equally 1/6, father or grandfather 1/6, the rest as in (1.a)'
 },
 {
    id:'1.u',
    heirs:'(1.t) with husband or wife',
    share:'grandmothers share equally 1/6, father or grandfather 1/6, husband 1/4, or wife 1/8, the rest as in (1.a)'
 }
]

const scheduleA_caseTwoTableData = [
  {
     id:'2.a',
     heirs:'with no other relatives',
     share:'If one only, she takes all the remainder. If more than one, they equally share all the remainder.'
  },
  {
     id:'2.b',
     heirs:'with wife',
     share:'1/8 to wife, the rest as in (2.a)'
  },
  {
     id:'2.c',
     heirs:'with husband',
     share:'1/4 to husband, the rest as in (2.a)'
  },
  {
     id:'2.d',
     heirs:'with father',
     share:'1/2 to the one daughter, 1/2 to the father. If more than one, they share 2/3 equally and 1/3 to father.'
  },
  {
     id:'2.e',
     heirs:'with mother',
     share:'1/4 to mother, 3/4 to daughter. If more than one, they share 4/5 equally and 1/5 to the mother.'
  },
  {
     id:'2.f',
     heirs:'with both parents',
     share:'1/6 to mother, 1/3 to father, 1/2 to daughter. If more than one, 2/3 to daughters equally, 1/6 to mother, and 1/6 to father.'
  },
  {
     id:'2.g',
     heirs:'with wife and father',
     share:'1/8 to wife, 1/2 to daughter, and 3/8 to father. If more than one, 2/3 to daughters equally,1/8 to wife,and 5/24 to father'
  },
  {
     id:'2.h',
     heirs:'with wife and mother',
     share:'1/8 to wife, 7/32 to mother, 21/32 to daughter. If more than one, 1/8 to wife, 7/40 to mother, and 7/10 to daughters equally.'
  },
  {
    id:'2.i',
    heirs:'with wife and both parents',
    share:'1/8 to wife, 1/6 to mother, 5/24 to father, and 1/2 to daughter. If more than one, 3/27 to wife, 4/27 to mother, 4/27 to father, and 16/27 to daughters equally.'
  },
  {
      id:'2.j',
      heirs:'with husband and father',
      share:'1/4 to husband, 1/4 to father, and &frac12; to daughter. If more than one, 3/13 to husband, 2/13 to father, and 8/13 to daughters equally.'
  },
  {
      id:'2.k',
      heirs:'with husband and mother',
      share:'1/4 to husband, 7/36 to mother, 5/9 to daughter. If more than one, 3/13 to husband, 2/13 to mother, and 8/13 to daughters equally.'
  },
  {
      id:'2.l',
      heirs:'with husband and both parents',
      share:'3/13 to husband, 2/13 to father, 2/13 to mother, and 6/13 to daughter. If more than one, 3/15 to husband, 2/15 to father, 2/15 to mother, and 8/15 to daughters equally.'
  },
  {
      id:'2.m',
      heirs:'with father of father, no father, and no brothers',
      share:'1/2 to father of father, 1/2 to daughter. If more than one, 1/3 to father of father and 2/3 to daughters equally.'
  },
  {
      id:'2.n',
      heirs:'(2.m) with wife',
      share:'As in (2.g), but father of father in place of father.'
  },
  {
      id:'2.o',
      heirs:'(2.m) with husband',
      share:'As in (2.j), but father of father in place of father.'
  },
  {
      id:'2.p',
      heirs:'(2.m) with mother, or without mother but with either mother of father or mother of mother',
      share:'As in (2.f), but father of father in place of father and grandmother in place of mother; the two grandmothers divide share of mother equally between themselves.'
  },
  {
      id:'2.q',
      heirs:'(2.p) with wife',
      share:'As in (2.i), but father of father in place of father and grandmother in place of mother; the two grandmothers divide the share of mother equally between themselves.'
  },
  {
    id: '2.r',
    heirs: '(2.p) with husband',
    share: 'As in (2.l) but father of father in place of father and grandmother in place of mother; the two grandmothers divide the share of mother equally between themselves.'
  },
  {
    id: '2.s',
    heirs: '(2.p), (2.q), (2.r) but in place of mother, both mother of mother mother of father; or mother of mother of mother, mother of mother father and mother of father of father; disregard mother of father of mother',
    share: 'The two grandmothers (or the three great grandmothers) share equally what is assigned to the mother or one grandmother in cases (2.p), (2.q), and (2.r); the rest as in (2.p), (2.q),and (2.r) respectively.'
  },
  {
    id: '2.t',
    heirs: 'with son of son',
    share: '1/2 to daughter, 1/2 to son of son. If more than one, 2/3 to daughters equally and 1/3 to son of son.'
  },
  {
    id: '2.u',
    heirs: 'with more than one son of son(s) and any number of daughters of son(s)',
    share: 'As in (2.t), but the share of son of son is divided among son of son(s) and daughters of son(s) according to rules stated in (1.a).'
  },
  {
    id: '2.v',
    heirs: '(2.t) or (2.u) with wife or husband',
    share: '1/2 to daughter, 1/8 to wife, or 1/4 to husband, the rest to children of son(s) as in (2.t) or (2.u). If more than one daughter, 2/3 to daughters equally, 1/4 to husband or 1/8 to wife, the rest to children of son(s) as in (2.t) or (2.u).'
  },
  {
    id: '2.w',
    heirs: '(2.v) with both parents',
    share: '1/2 to daughter, 1/8 to wife, 1/6 to mother, 1/6 to father, and 1/24 to grandchildren as in (2.t) or (2.u). 6/13 to daughter, 3/13 to husband, 2/13 to father, 2/13 to mother, nothing to grandchildren. If more than one daughter, 16/27 to daughters equally, 3/27 to wife, 4/27 to mother, 4/27 to father, nothing to grandchildren. 8/15 to daughters, 3/15 to husband and 2/15 to mother, 2/15 to father, nothing to grandchildren.'
  },
  {
    id:'2.x',
    heirs:'(2.v) with one parent',
    share:'1/2 to daughter, 1/8 to wife, 1/6 to parent, and 5/24 to children of son(s) as in (2.t) and (2.u); or, 1/2 to daughter, 1/4 to husband, 1/6 to parent, and 1/12 to children of son(s) as in (2.t)and (2.u). If more than one daughter, 2/3 to daughters, 1/8 to wife, 1/6 to parent and 1/24 to children of son(s) as in (2.t) and (2.u); or, 8/13 to daughters, 2/13 to parent and 3/13 to husband, nothing to grandchildren.'
  },
  {
    id:'2.y',
    heirs:'(2.v) with father of father, no father and no brothers, and mother; or with father of father, no father and no brother(s) and grandmother(s) on either side,and no mother.',
    share:'As in (2.w), but replace father of father for father and grandmother(s) for mother. Share of grandmothers is divided equally between them.'
  },
  {
    id:'2.z',
    heirs:'with sister(s) of same parents with daughters of son(s) and no sons of sons',
    share:'3/4 to the daughter and 1/4 to daughter(s) of son(s), equally between them. If more than one daughter; all to daughters, nothing to daughter(s) of son(s).'
  },
  {
    id:'2.aa',
    heirs:'(no brothers), or with brother(s) of the same two parents (no sisters)',
    share:'1/2 to the daughter, 1/2 to sister (or brother), or equally among all sisters (or brothers). If more than one daughter; 2/3 to daughters, 1/3 to sister (or brother) or equally among sisters (or brothers).'
  },
  {
      id:'2.bb',
      heirs:'with sister(s) and brother(s) of the same two parents',
      share:'1/2 to daughter, 1/2 to sister(s) and brother(s) on the basis of one share to female and two shares to male. If more than one daughter, 2/3 to daughters, 1/3 to sister(s) and brother(s) on the same basis.'
  },
  {
      id:'2.cc',
      heirs:'(2.aa) or (2.bb) with wife or husband',
      share:'1/2 to daughter, 1/8 to wife and 3/8 to sister(s) and/or brother(s) as in (2.aa) and (2.bb). 1/2 to daughter, 1/4 to'
  },
  {
      id:'2.dd',
      heirs:'with uncle(s) from same parents as father',
      share:'1/2 to daughter and the rest to uncle, or uncles equally between them. If more than one daughter, 2/3 to daughters and the rest to uncle or uncles equally between them.'
  },
  {
      id:'2.ee',
      heirs:'with one grandmother, either side, or both grandmothers',
      share:'5/6 to daughter and 1/6 to grandmother or grandmothers, equally between them. If more than one daughter, 5/6 to daughters and 1/6 to grandmother(s).'
  }
]

const scheduleA_caseFourTableData = [
  {
     id:"4.a",
     heirs:"father alone; or father and brother(s) and/or sister(s)",
     share:"all the remainder to father alone, nothing to brother(s) and sister(s)"
  },
  {
     id:"4.b",
     heirs:"father and wife or husband",
     share:"1/4 to wife, or 1/2 to husband, and the rest to father"
  },
  {
     id:"4.c",
     heirs:"father and mother, no brothers, no sisters",
     share:"1/3 to mother, the rest to father"
  },
  {
     id:"4.d",
     heirs:"(4.c) with husband or wife",
     share:"1/4 to wife, 1/4 to mother, the rest to father."
  },
  {
     id:"4.e",
     heirs:"both parents, with brother(s) and/or sister(s) and with wife or husband",
     share:"1/6 to mother, nothing to brother(s)and sister(s), the rest to father. 1/6 to mother, nothing to brother(s) and sister(s), 1/4 to wife, or 1/2 to husband; the rest to father."
  },
  {
     id:"4.f",
     heirs:"mother only",
     share:"she takes all the remainder"
  },
  {
     id:"4.g",
     heirs:"mother and husband or wife",
     share:"1/4 to wife, or 1/2 to husband, and the rest to mother"
  },
  {
     id:"4.h",
     heirs:"mother with one brother or one sister of the same two parents or on father’s side",
     share:"1/3 to mother, rest to brother. 2/5 to mother, the rest to sister."
  },
  {
     id:"4.i",
     heirs:"(4.h) with husband or wife",
     share:"1/3 to mother, 1/2 to husband or 1/4 to wife, the rest to brother. 4/13 to mother. 3/13 to wife, and 6/13 to sister. 2/8 to mother, 3/8 to husband, and 3/8 to sister."
  },
  {
     id:"4.j",
     heirs:"mother with at least two brothers, brother(s) and sister(s) all of same two parents or on father’s side",
     share:"1/6 to mother, the rest to brothers or brother(s) and sister(s) according to rules in (1.a)."
  },
  {
    id: "4.k",
    heirs: "(4.j) with husband or wife",
    share: "1/6 to mother, 1/4 to wife, or 1/2 to husband, the rest to brothers or brother(s) and sister(s) as in rules (1.a)"
  },
  {
    id: "4.l",
    heirs: "mother with two sisters or more, of the same two parents or on father’s side",
    share: "1/5 to mother, 4/5 to sisters equally between them"
  },
  {
    id: "4.m",
    heirs: "(4.l) with husband or wife",
    share: "3/13 to wife, 2/13 to mother, 8/13 to sisters equally between them. 3/7 to husband, 1/7 to mother, 3/7 to sisters equally between them"
  },
  {
    id: "4.n",
    heirs: "mother with one brother on mother’s side or one sister on mother’s side",
    share: "2/3 to mother, 1/3 to brother or sister"
  },
  {
    id: "4.o",
    heirs: "(4.n) with husband or wife",
    share: "1/4 to wife, 1/2 to mother,1/4 to brother or sister. 1/2 to husband, 1/3 to mother, 1/6 to brother or sister."
  },
  {
    id: "4.p",
    heirs: "mother with more than one brother and/or sister on mother’s side",
    share: "1/3 to mother, 2/3 to brother(s) and sister(s), equally between them all."
  },
  {
    id: "4.q",
    heirs: "(4.p) with husband or wife",
    share: "1/4 to wife, 1/4to mother, 1/2 to brother(s) and sister(s) equally between them all. 1/2 to husband, 1/6 to mother, 1/3 to brother(s) and sister(s) equally between them all."
  },
  {
    id: "4.r",
    heirs: "mother with father of father, no brother(s), no sister(s)",
    share: "1/3 to mother, the rest to father of father"
  },
  {
    id: "4.s",
    heirs: "(4.r) with husband or wife",
    share: "1/3 to mother, 1/4 to wife or 1/2 to husband, the rest to grandfather"
  },
  {
    id: "4.t",
    heirs: "mother with son of brother, (the brother is of the same parents)",
    share: "1/3 to mother, the rest to son of brother"
  },
  {
    id: "4.u",
    heirs: "mother with children of brother(s), (the brother is of the same parents)",
    share: "1/3 to mother, the rest to children of brother(s) according to rules in (1.a)"
  },
  {
    id: "4.v",
    heirs: "(4.t) or (4.u) with wife or husband",
    share: "1/3 to mother, 1/4 to wife, or 1/2 to husband and the rest to son or children of brother(s) as in (4.t) or (4.u)"
  },
  {
    id: "4.w",
    heirs: "mother with brother of father of the same two parents",
    share: "1/3 to mother, the rest to brother of father"
  },
  {
    id: "4.x",
    heirs: "mother with brother(s) of father and any number of sisters of father, all of the same two parents",
    share: "1/3 to mother, the rest to brother(s) and sister(s) of father according to rules in (1.a)"
  },
  {
    id: "4.y",
    heirs: "(4.w) and (4.x) with wife or husband",
    share: "1/3 to mother, 1/4 to wife, or 1/2 to husband, the rest to brother of father or brother(s) and sister(s) of fathers as in (4.x)"
  },
  {
    id: "4.z",
    heirs: "father with mother of mother and no mother",
    share: "1/6 to mother of mother, the rest to father"
  },
  {
    id: "4.aa",
    heirs: "mother with brother(s) and father of father",
    share: "1/6 to mother, the rest among brother(s) and father of father equally, unless grandfather’s share goes below 1/3 (if it does, he gets 1/3 and the rest to brothers equally)"
  },
  {
    id: "4.bb",
    heirs: "mother with father of father and brother(s) and any number of sister(s), all of the same two parents or on father’s side",
    share: "as in (4.aa) and apply rules of (1.a) for brother(s) and sister(s)"
  }
]

const scheduleA_caseFiveTableData = [
  {
     "id":"5.a",
     "heirs":"wife only",
     "share":"1/4 to wife, the rest to the Islamic Information Society of Calgary to be used as a Waqf whose net return only should be used for IISC's activities in North America"
  },
  {
     "id":"5.b",
     "heirs":"husband only",
     "share":"1/2 to husband, the rest as in (5.a)"
  },
  {
     "id":"5.c",
     "heirs":"husband and wife, with one brother or more and any number of sisters",
     "share":"1/2 to husband, or 1/4 to wife, the rest to brother(s) and sister(s) according to rules in (1.a)"
  },
  {
     "id":"5.d",
     "heirs":"husband or wife,with sister(s), no brothers",
     "share":"1/2 to husband or 1/4 to wife, the rest to the sister or equally between sisters"
  },
  {
     "id":"5.e",
     "heirs":"husband or wife, with son or sons of brother(s), or son(s) and any number of daughters of brother(s)",
     "share":"As in (5.c) but niece(s) and nephew(s) replace sister(s) and brother(s)"
  },
  {
     "id":"5.f",
     "heirs":"husband or wife, with brother(s) of father",
     "share":"1/2 to husband or 1/4 to wife and the rest to uncle or uncles equally between them"
  },
  {
     "id":"5.g",
     "heirs":"husband, or wife, with one brother of father or more, and any number of sisters of father",
     "share":"1/2 to husband or 1/4 to wife, rest to uncle(s) and aunt(s) according to the rules in (1.a)"
  }
]

// Define an object that maps numbers to their word form
const numbersToWords = {
  0: "zero",
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
  8: "eight",
  9: "nine",
  10: "ten",
  11: "eleven",
  12: "twelve",
  13: "thirteen",
  14: "fourteen",
  15: "fifteen",
  16: "sixteen",
  17: "seventeen",
  18: "eighteen",
  19: "nineteen",
  20: "twenty",
  30: "thirty",
  40: "forty",
  50: "fifty",
  60: "sixty",
  70: "seventy",
  80: "eighty",
  90: "ninety",
};

// Define the convertNumberToWords function
function convertNumberToWords(number) {
  // if number present in object no need to go further
  if (number in numbersToWords) return numbersToWords[number];

  // Initialize the words variable to an empty string
  let words = "";

  // If the number is greater than or equal to 100, handle the hundreds place (ie, get the number of hundres)
  if (number >= 100) {
    // Add the word form of the number of hundreds to the words string
    words += convertNumberToWords(Math.floor(number / 100)) + " hundred";

    // Remove the hundreds place from the number
    number %= 100;
  }

  // If the number is greater than zero, handle the remaining digits
  if (number > 0) {
    // If the words string is not empty, add "and"
    if (words !== "") words += " and ";

    // If the number is less than 20, look up the word form in the numbersToWords object
    if (number < 20) words += numbersToWords[number];
    else {
      // Otherwise, add the word form of the tens place to the words string
      //if number = 37, Math.floor(number /10) will give you 3 and 3 * 10 will give you 30
      words += numbersToWords[Math.floor(number / 10) * 10];

      // If the ones place is not zero, add the word form of the ones place
      if (number % 10 > 0) {
        words += "-" + numbersToWords[number % 10];
      }
    }
  }

  // Return the word form of the number
  //console.log("returning words: ", words);
  return words;
}

// Create Document Component
export default function WillDocument() {
  
  let fetchedData = JSON.parse(sessionStorage.getItem("data"));

  if(fetchedData === null){
    fetchedData = sample
  }

  //console.log("fetchedData", fetchedData);
  //console.log("fetchedData.personal_name", fetchedData.personal_name);
  //console.log("fetchedData.personal_name", fetchedData.executor_backup_name);
  
  //destructure our data
  
  const data_PersonalName = fetchedData.personal_name;
  const data_PersonalAddress = fetchedData.personal_address;
  const data_PersonalProvince = fetchedData.personal_province;

  const data_ExecutorMainName = fetchedData.executor_main_name;
  const data_ExecutorMainAddress = fetchedData.executor_main_address;

  const data_ExecutorBackupName = fetchedData.executor_backup_name;
  const data_ExecutorBackupAddress = fetchedData.executor_backup_address;

  const data_GuardianMainName = fetchedData.guardian_main_name;
  const data_GuardianMainAddress = fetchedData.guardian_main_address;

  const data_GuardianBackupName = fetchedData.guardian_backup_name;
  const data_GuardianBackupAddress = fetchedData.guardian_backup_address;

  const data_CharitiesArray = fetchedData.charities_array;
  if(data_CharitiesArray[0].charityNumber !== undefined && data_CharitiesArray[0].charityNumber !== null && data_CharitiesArray[0].charityNumber !== "") {
    //console.log("data_CharitiesArray[0].charityNumber: " + data_CharitiesArray[0].charityNumber);
  } else {
    //console.log("data_CharitiesArray[0].charityNumber is undefined");
  }
  
  const data_organizationTableData = data_CharitiesArray.map(obj => {
    return {
      name: obj.name + ((obj.address !== "") ? (" of " + obj.address) : "") 
        + ((obj.charityNumber != undefined && obj.charityNumber !== null && obj.charityNumber !== "") ? ("\n\nCharity Number: " + obj.charityNumber) : ("")),
      purpose: 
        (obj.type == "Charity") ? 
          ((obj.purpose != undefined && obj.purpose !== null && obj.purpose !== "") ? (obj.purpose) : ("To be used for any project in the organization.")) :
          ("The individual can use the money as he/she deems fit."),
      
      inNum: obj.percentage,
    }    
  })

 //console.log("data_organizationTableData", data_organizationTableData);

  const data_BackupType = fetchedData.backup_type;
  const data_BackupCharityName = fetchedData.backup_charityName;
  const data_BackupCharityAddress = fetchedData.backup_charityAddress;
  const data_BackupCharityPurpose = fetchedData.backup_charityPurpose;
  const data_BackupCharityNumber = fetchedData.backup_charityNumber;




  
  return (
    <Document style={{ width: "1024px" }}>
      <Page size="Letter" style={styles.body}>
        <Text style={styles.header} fixed>
          Last Will and Testament
        </Text>
        <View style={{ marginBottom: 5 }}>
          <View style={styles.imageView}>
            <Image style={styles.image} src={bismillah} />
          </View>

          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}> LAST WILL AND TESTAMENT </Text>
          </View>
          <View>
            <Text style={styles.textContent}>
              {"\n\n"}
              I, <Text style={styles.textContentDynamic}>{data_PersonalName?.toString()}</Text>, 
              presently residing at <Text style={styles.textContentDynamic}>{data_PersonalAddress?.toString()}</Text>, 
              being of sound mind and memory, do hereby
              revoke any and all former Wills and codicils made by me, and do
              make, ordain, publish, and declare this my Last Will and
              Testament.
            </Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}> PREAMBLE </Text>
          </View>
          <View>
            <Text style={styles.textContent}>
              I bear witness that there is no deity but Allah, the One, the
              Merciful, the Almighty Creator of the heavens and the earth and
              all therein—God of Abraham, Moses, Jesus, Muhammad, and all the
              Prophets, mercy and peace be upon them all. He is One God and He
              has no partner. And I bear witness that the Prophet Muhammad is
              His Servant and his Messenger and the last of all the Prophets,
              mercy and peace be upon him. I bear witness that Allah is the
              Truth, that His promise is Truth, and that the Meeting with Him is
              Truth. I bear witness that Paradise is Truth, and that Hell is
              Truth. I bear witness that the coming of the Day of Judgement is
              Truth, there is no doubt about it, and that Allah, who is exalted
              about all deficiencies and imperfections, will surely resurrect
              the dead of all generations of mankind, first and last and those
              in between.
            </Text>
          </View>
          <View>
            <Text style={styles.textContent}>
              This is my counsel to my relatives and friends, my Muslim brothers
              and sisters, and all those who remain after me: that they strive
              to be true Muslims, that they submit to their Creator—may He Be
              exalted—and worship Him as He alone is to be worshiped, fear Him
              as He alone is to be feared, and love Him and His Prophet Muhammad
              with a complete love that is rivaled by nothing besides them. Let
              them obey Him and hold Fast to His Shari’ah. Let them spread and
              firmly establish His religion of Islam and let them die only in a
              state of complete submission to His Will.
            </Text>
            <Text style={styles.textContent}>
              I remind them that no man and no woman die before his/her time.
              The exact duration of each life span is precisely determined
              before we are born by the All-Powerful Creator, may He be exalted.
              Death is tragic only for the one who lived out his/her life in
              self-deception without submitting to the Creator and preparing for
              the final return to Him. So, do not preoccupy yourselves with my
              death, but instead make the proper preparations for your own.
            </Text>
            <Text style={styles.textContent}>
              Maintain patience and self-composure as the religion of Islam
              requires. Islam permits relatives to mourn for no more than three
              days, although a widow is allowed to mourn for four lunar months
              and ten days, until her iddah (period of waiting) is completed.
              Wailing and excessive lamentation is forbidden by the Creator, and
              it reflects only a lack of understanding and dissatisfaction with
              the Will of the Creator, may He be exalted.
            </Text>
            <Text style={styles.textContent}>
              Finally, I ask all my relatives, friends, and all others—whether
              they choose to believe as I believed or not—to honor my
              Constitutional right to these beliefs. I ask them to honor this
              document which I have made, and not to try to obstruct it or
              change it in any way. Rather, let them see that I am buried as I
              have asked to be buried and let my properties be divided as I
              wanted them to be divided.
            </Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2NoPadding}>ARTICLE I:</Text>
            <Text style={styles.textHeadingH2}> FUNERAL AND BURIAL RIGHTS </Text>
          </View>
          <View>
            <Text style={styles.textContent}>
              I ordain that no autopsy or embalming be done on my body unless
              required by law and that without unjustified delay my body be
              washed, wrapped with cloth free of any ornaments and other
              articles, prayed for, then buried, which should all be done by
              Muslims in complete accordance with Islamic tenets.
            </Text>
            
            <Text style={styles.textContentBulletPoint}>
              a. I hereby nominate and appoint <Text style={styles.textContentDynamic}>{data_ExecutorMainName?.toString()}</Text>, 
              residing at <Text style={styles.textContentDynamic}>{data_ExecutorMainAddress?.toString()}</Text> to execute
              these and other necessary provisions for my Islamic funeral and
              burial.&nbsp;
              
              {/* {console.log("during render: " + data_ExecutorBackupName)} */}
              
              {
                !!data_ExecutorBackupName?.toString() && data_ExecutorBackupName?.toString().trim().length > 0 && (
                  <Text style={styles.textContentBulletPoint}>
                    In the event that he/she shall be unwilling or unable to
                  execute, I nominate and appoint <Text style={styles.textContentDynamic}>{data_ExecutorBackupName?.toString()}</Text>, 
                   residing at <Text style={styles.textContentDynamic}>{data_ExecutorBackupAddress?.toString()}</Text>.&nbsp;  
                  </Text>
                )
              }
               In the event that he/she shall be unwilling or unable, I nominate and
              appoint the president of the local Muslim community or association
              in the area where I die to execute these provisions of funeral and
              burial.
            </Text>
            <Text style={styles.textContentBulletPoint}>
              b. In the event of legal difficulties in the execution of this
              Article, I direct the above-named person to seek counsel from an
              Islamic Organization which adheres to the Qur'an and Authentic
              Sunnah of the Prophet Muhammad (pbuh).
            </Text>
            <Text style={styles.textContentBulletPoint}>
              c. I ordain that absolutely no non-Islamic religious service or
              observance shall be conducted upon my death or on my body.
            </Text>
            <Text style={styles.textContentBulletPoint}>
              d. I ordain that no pictures, crescents or stars, decorations,
              crosses, flags, any symbols—Islamic or otherwise—or music shall be
              involved at any stage of the process of conducting my burial or ever
              be placed at the site of my grave.
            </Text>
            <Text style={styles.textContentBulletPoint}>
              e. I ordain that my body shall not be transported over any
              unreasonable distance from the locality of my death, particularly
              when such transportation would necessitate embalming, unless when
              long distance transportation is required to reach the nearest Muslim
              cemetery, or any other cemetery selected by my Muslim family.
            </Text>
            <Text style={styles.textContentBulletPoint}>
              f. I ordain that my grave shall be dug deep into the ground in
              complete accordance with the specifications of Islamic practice and
              that it faces the direction of Qiblah (the direction of the city of
              Mecca in the Arabian Peninsula, towards which Muslims face for
              prayer).
            </Text>
            <Text style={styles.textContentBulletPoint}>
              g. I ordain that my body shall be buried without a casket or any
              encasement that separates the wrapped body from the surrounding
              soil. In the event that local laws require casket encasement, I
              ordain that such encasement be of the simplest, most modest, and
              least expensive type possible. I further ordain that the encasement
              be left open during burial and filled with dirt, unless prohibited
              by law.
            </Text>
            <Text style={styles.textContentBulletPoint}>
              h. I I ordain that my grave shall be level with the ground or
              slightly mounded, with no construction or permanent structure of any
              kind over it. The marking—if necessary—should be a simple rock or
              marker, merely to indicate the presence of the grave. There should
              be no inscriptions or symbols on the said marking.
            </Text>
          </View>
          
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2NoPadding}>{"\n"}ARTICLE II: </Text>
            <Text style={styles.textHeadingH2}> EXECUTOR AND GUARDIAN </Text>
          </View>
          <Text style={styles.textContentBulletPoint}>
            a. I hereby nominate and appoint, <Text style={styles.textContentDynamic}>{data_ExecutorMainName?.toString()}</Text>, 
            residing at <Text style={styles.textContentDynamic}>{data_ExecutorMainAddress?.toString()}</Text> to be the
            executor of this, my Last Will and Testament.
            
            {
              !!data_ExecutorBackupName?.toString() && data_ExecutorBackupName?.toString().trim().length > 0 && (
                <Text style={styles.textContentBulletPoint}>
                  In the event that he/she will be unwilling or unable to act as executor, 
                  I nominate and appoint <Text style={styles.textContentDynamic}>{data_ExecutorBackupName?.toString()}</Text>, 
                  residing at <Text style={styles.textContentDynamic}>{data_ExecutorBackupAddress?.toString()}</Text>, 
                  to be executor of this, my Last Will and Testament.  
                </Text>
              )
            }
          </Text>
          <Text style={styles.textContentBulletPoint}>
            b. I give my executor herein named power to settle any claim for or
            against my estate and power to sell any property, real, personal, or
            mixed, in which I have an interest, without court order and without
            bond. I direct no bond or surety for any bond be required for my
            executor in the performance of his/her duties.
          </Text>

          {
              !!data_GuardianMainName?.toString() && data_GuardianMainName?.toString().trim().length > 0 && (
                <View>
                  <Text style={styles.textContentBulletPoint}>
                    c. I hereby nominate and appoint <Text style={styles.textContentDynamic}>{data_GuardianMainName?.toString()}</Text>, 
                    residing at <Text style={styles.textContentDynamic}>{data_GuardianMainAddress?.toString()}</Text> to be the
                    guardian of the persons and estates of such of my children who shall
                    be minor at and after my death, during their minority, so long as
                    said guardian remains a Muslim of sound mind and judgement. 
                    
                    
                    {
                      !!data_GuardianBackupName?.toString() && data_GuardianBackupName?.toString().trim().length > 0 && (
                        <Text style={styles.textContentBulletPoint}>
                          &nbsp;In the event he/she shall be unwilling or unable to act as guardian, 
                          I nominate and appoint <Text style={styles.textContentDynamic}>{data_GuardianBackupName?.toString()}</Text>, 
                          residing at <Text style={styles.textContentDynamic}>{data_GuardianBackupAddress?.toString()}</Text> to be the guardian.

                        </Text>
                      )
                    }
                    </Text>
                  </View>
              )
            }
            



          
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>{"\n"}ARTICLE III: </Text>
            <Text style={styles.textHeadingH2}> DEBTS AND EXPENSES </Text>
          </View>
          <Text style={styles.textContentBulletPoint}>
            a. I direct that my executor apply first, the assets of my estate to
            the payment of all my legal debts—including such expenses incurred
            by my last illness and burial as well as the expenses of
            administrating my estate. I direct the said executor to pay any
            “obligations to Allah” (Huquq Allah) which are binding on me, such
            as unpaid Zakah, Kaffarat, or unperformed pilgrimage (Hajj).
          </Text>
          <Text style={styles.textContentBulletPoint}>
            b. I direct that all inheritance, state, and succession taxes
            (including interest and other penalties thereon) payable by reason
            of my death shall be paid out of and be charged generally against
            the principal of my residuary estate, without reimbursement from any
            person; except that this provision shall not be construed as a
            waiver of any right which my executor has, by law or otherwise, to
            claim reimbursement for any such taxes which become payable on
            account of property, if any, over which I have a power of
            appointment.
          </Text>
          
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2NoPadding}>{"\n"}ARTICLE IV: </Text>
            <Text style={styles.textHeadingH2NoPadding}>
              CHARITABLE CONTRIBUTIONS AND
            </Text>
            <Text style={styles.textHeadingH2NoPadding}>
              TESTAMENTARY TRANSFER
            </Text>
          </View>

          {
            data_organizationTableData[0].name === "" && (
              <Text style={styles.textContentBulletPoint}>
                Charitable Contributions have not been specified in this Will.
              </Text>
            )
          }

          {
            data_organizationTableData[0].name !== "" && (

              <View>
                <Text style={styles.textContentBulletPoint}>
                  I direct and ordain my executor to pay the following contributions
                  and transfers, not to exceed one third of the remainder of my estate
                  after making provision for payments of my obligations mentioned in
                  Article III, to the following named persons and organizations:
                </Text>
                
                


                <View style={styles.table} break>
                  {/* Render table header */}
                  <View style={[styles.row, styles.headerCell]}>
                    <View style={styles.cell3Column}>
                      <Text style={styles.tableText}>
                        Name of Persons or organizations
                      </Text>
                    </View>
                    <View style={styles.cell3Column}>
                      <Text style={styles.tableText}>
                        Purpose
                      </Text>
                    </View>
                    <View style={styles.cell3Column}>
                      <Text style={styles.tableText}>
                        Percent of remainder of my estate after execution of Article
                        III
                      </Text>
                    </View>
                  </View>

                  {/* Render table rows */}
                  {data_organizationTableData.map((row, index) => (
                    <View style={styles.row} key={index}>
                      <View style={styles.cell3Column}>
                        <Text style={styles.tableText}>{row.name}</Text>
                      </View>
                      <View style={styles.cell3Column}>
                        <Text style={styles.tableText}>{row.purpose}</Text>
                      </View>
                      <View style={styles.cell3Column}>
                        <Text style={styles.tableText}>{row.inNum}</Text>
                      </View>
                    </View>
                  ))}
                  
                  
          
                </View>
                <Text style={styles.textContentBulletPoint}>
                  If any individual above is dead or unable to take his portion it should go to 
                  
                  <Text style={styles.textContentDynamic}>{
                    " " + data_BackupCharityName?.toString() +
                    " " + (data_BackupCharityNumber != undefined && !!data_BackupCharityNumber?.toString() && data_BackupCharityNumber?.toString().trim().length > 0 ? "(" + data_BackupCharityNumber?.toString() + ")" : "")

                  }</Text> 


                  {
                    data_BackupCharityAddress != undefined && !!data_BackupCharityAddress?.toString() && data_BackupCharityAddress?.toString().trim().length > 0 && (
                      <Text>
                      , of&nbsp;<Text style={styles.textContentDynamic}>{data_BackupCharityAddress?.toString()}</Text> 
                      </Text>
                    )
                  }

                  {
                    data_BackupCharityPurpose != undefined && !!data_BackupCharityPurpose?.toString() && data_BackupCharityPurpose?.toString().trim().length > 0 && (
                      <Text>
                          , towards the following preferred purpose(s): <Text style={styles.textContentDynamic}>{data_BackupCharityPurpose?.toString()}</Text>
                      </Text>
                    )
                  }.


                  {"\n\n"} For the charities mentioned above, if any of the purposes are not offered by the organization I give the executor the permission to either choose another project in the same charity or choose another charity that offers the same project.

                </Text>
                
              </View>
            )
          }

          
          


          
          
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2NoPadding}>{"\n"}ARTICLE V: </Text>
            <Text style={styles.textHeadingH2}>
              DISTRIBUTION OF THE REMAINDER OF MY ESTATE
            </Text>
          </View>
          <Text style={styles.textContentBulletPoint}>
            a. I direct, devise, and bequest all the residue and remainder of my
            estate after making provision for payment of my obligations and
            distributions provided in Article III and IV, to only my Muslim
            heirs whose relation to me, whether ascending or descending, has
            occurred through Islamic or lawful marriage at each and every point.
            The distribution of the residue and remainder of my estate shall be
            made strictly in accordance with:
          </Text>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>
              SCHEDULE A: MAWARITH (INHERITANCE)
            </Text>
          </View>
          <Text style={styles.textContentBulletPoint}>
            a. This Schedule A is signed by me as a part of this Last Will and
            Testament.
          </Text>
          <Text style={styles.textContentBulletPoint}>
            b. I direct that no part of the residue and remainder of my estate
            shall be inherited by any non-Muslim relative, whether he/she is a
            kin or an in-law, spouse, parent, or child. I further direct and
            ordain that any non-Muslim relative be disregarded and disqualified
            in the application of the named schedule.
          </Text>
          <Text style={styles.textContentBulletPoint}>
            c. Should I die as a result of murder, I direct that the adjured
            murderer, principal or accessory in the murder shall be disqualified
            to receive any part of my estate.
          </Text>
          <Text style={styles.textContentBulletPoint}>
            d. I direct that no part of my estate shall be given to relatives
            whose relationship to me, ascending or descending, has occurred
            through non-Islamic and unlawful marriage, or through adoption, at
            each and every point, except the following:
          </Text>
          <Text style={styles.textContentBulletPointLevel2}>
            i. Legatees specifically named in Article IV
          </Text>
          <Text style={styles.textContentBulletPointLevel2}>
            ii. A relative who is related to me through his/her biological
            mother
          </Text>
          <Text style={styles.textContentBulletPoint}>
            e. I direct and devise that any fetus, conceived before my death,
            whose relationship to me qualifies it to be an heir according to
            this Article, shall be considered as an heir if the following
            condition is fulfilled: the fetus should be born alive within 365
            days of my death. I further direct and devise that whenever there
            exists a fetus who may become an heir according to this section, the
            whole distribution of the residue and remainder of my estate after
            the execution of Articles III and IV shall be delayed until after
            the birth of the fetus; or that the largest potential share of the
            fetus be set aside until its birth alive. Should the fetus be born
            alive, but qualify for a lesser share, or should it not be born
            alive within the 365 days, any surplus of the set aside amount must
            be returned to the estate and distributed according to Schedule A.
          </Text>
          <Text style={styles.textContentBulletPoint}>
            f. I direct, devise, and bequest all the residue and remainder of my
            estate of every nature and kind and whenever situated after making
            provisions for payments of my obligations and distribution of my
            estate as provided in Articles III and IV. I further direct, devise,
            and ordain that any portion of my estate disclaimed or refused to be
            received by any of the legatees named or referred to in this Last
            Will and Testament, or the remainder of my estate in the event of
            non-existence of my Islamic heirs, shall be given to 
            <Text style={styles.textContentDynamic}>{
              " " + data_BackupCharityName?.toString() +
              " " + (data_BackupCharityNumber != undefined && !!data_BackupCharityNumber?.toString() && data_BackupCharityNumber?.toString().trim().length > 0 ? "(" + data_BackupCharityNumber?.toString() + ")" : "")

            }</Text> 


            {
              data_BackupCharityAddress != undefined && !!data_BackupCharityAddress?.toString() && data_BackupCharityAddress?.toString().trim().length > 0 && (
                <Text>
                 , of&nbsp;<Text style={styles.textContentDynamic}>{data_BackupCharityAddress?.toString()}</Text> 
                </Text>
              )
            }

            {
              data_BackupCharityPurpose != undefined && !!data_BackupCharityPurpose?.toString() && data_BackupCharityPurpose?.toString().trim().length > 0 && (
                <Text>
                    , towards the following preferred purpose(s): <Text style={styles.textContentDynamic}>{data_BackupCharityPurpose?.toString()}</Text>
                </Text>
              )
            }.


          </Text>
          
          <View wrap={false}>
            <Text style={styles.textHeadingH2NoPadding}>{"\n"}ARTICLE VII: </Text>
            <Text style={styles.textHeadingH2}>SEPARABILITY</Text>
            <Text style={styles.textContent}>
              I direct and ordain that if any part of this Last Will and Testament
              is determined invalid by a court of competent jurisdiction, the
              other parts shall remain valid and enforceable.
            </Text>
            <Text style={styles.textContent}>
              In witness whereof, I have hereunto set my hand and seal
              this_______day of _______of the year_______.
            </Text>

            <Text style={styles.textBlankLine}>______________________________</Text>
            <Text style={styles.textContent}>Legal Name</Text>
            <Text style={styles.textBlankLine}>______________________________</Text>
            <Text style={styles.textContent}>Muslim Name, if different</Text>
          </View>
          
          
          
          <Text style={styles.textContent}>
            We hereby certify that the forgoing instrument was on the date
            thereof signed, published, and declared by the Testator <Text style={styles.textContentDynamic}>{data_PersonalName?.toString()}</Text>,
            and as for his/her Last Will and Testament, in our presence, who at
            his/her request and in his/her presence, and in the presence of each
            other, have hereunto subscribed our names as witnesses thereto,
            believing said Testator at the time of the signing to be of sound
            mind and memory.
          </Text>

          <View wrap={false}>
            <Text style={styles.textBlankLine}>1. _________________________________ of __________________________________________</Text>
            <Text style={styles.textBlankLine}>2. _________________________________ of __________________________________________</Text>
            <Text style={styles.textBlankLine}>3. _________________________________ of __________________________________________</Text>
          </View>

          <Text style={styles.textBlankLine}>
            This document, comprising_______pages, including Schedule A, are
            made in_______copies. The original is with me, one copy is
            with_____________________ , and one copy is
            with:____________________________
          </Text>
          
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>{"\n\n"}Notary Public (optional):</Text>
          </View>


          <View style={styles.tableNoBorder}>
            {/* Render table rows */}
            
            <View style={styles.row}>
              <View style={styles.cell3ColumnNoBorder}>
                <Text style={styles.tableTextNoPadding}>________________________________</Text>
              </View>
              <View style={styles.cell3ColumnNoBorder}>
                <Text style={styles.tableTextNoPadding}>________________________________</Text>
              </View>
              <View style={styles.cell3ColumnNoBorder}>
                <Text style={styles.tableTextNoPadding}>________________________________</Text>
              </View>
            </View>

            <View style={styles.row}>
              <View style={styles.cell3ColumnNoBorder}>
                <Text style={styles.tableTextNoPadding}>Name</Text>
              </View>
              <View style={styles.cell3ColumnNoBorder}>
                <Text style={styles.tableTextNoPadding}>Signature</Text>
              </View>
              <View style={styles.cell3ColumnNoBorder}>
                <Text style={styles.tableTextNoPadding}>Commission Expires</Text>
              </View>
            </View>
            
          </View>
          
          <View style={styles.imageView}  break>
            <Text style={styles.textHeadingH2}>SCHEDULE A:</Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>MAWARITH (THE ISLAMIC DISTRIBUTION OF THE ESTATE)</Text>
          </View>
          <Text style={styles.textContent}>
          I ordain and direct that this schedule be used as the only reference for distribution of the residue and remainder of my estate, referred to in Article V. This schedule is a part of my Last Will and Testament.
          </Text>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>CASE NO. 1:</Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>ONE SON OR MORE AND ANY NO. OF DAUGHTERS</Text>
          </View>

          <View style={styles.table}>
            {/* Render table header */}
            <View style={[styles.row, styles.headerCell]}>
            <View style={styles.cellId}>
                <Text style={styles.tableText}>
                  ID
                </Text>
              </View>
              <View style={styles.cellHeir}>
                <Text style={styles.tableText}>
                Surviving Heirs
                </Text>
              </View>
              <View style={styles.cellShare}>
                <Text style={styles.tableText}>
                Share of the Remainder of My Estate
                </Text>
              </View>
            </View>

            {/* Render table rows */}
            {scheduleA_caseOneTableData.map((row, index) => (
              <View style={styles.row} key={index}>
                <View style={styles.cellId}>
                  <Text style={styles.tableText}>{row.id}</Text>
                </View>
                <View style={styles.cellHeir}>
                  <Text style={styles.tableText}>{row.heirs}</Text>
                </View>
                <View style={styles.cellShare}>
                  <Text style={styles.tableText}>{row.share}</Text>
                </View>
              </View>
            ))}
          </View>
          <Text style={styles.textContent}>
            In each of (1.a) through (1.u), disregard all other relatives not
          mentioned in the relevant subcases.
          </Text>

          <Text style={styles.textContent}>
            IF TESTATOR&rsquo;S CASE IS UNDER NO. (1), BUT NOT FOUND ABOVE, THE
          EXECUTOR MUST follow the advice of an Islamic Organization that adheres to the
          Qur&#39;an and authentic Sunnah of the Prophet (pbuh).
          </Text>





          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>CASE NO. 2:</Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>DAUGHTER OR DAUGHTERS; NO SONS</Text>
          </View>

          <View style={styles.table}>
            {/* Render table header */}
            <View style={[styles.row, styles.headerCell]}>
            <View style={styles.cellId}>
                <Text style={styles.tableText}>
                  ID
                </Text>
              </View>
              <View style={styles.cellHeir}>
                <Text style={styles.tableText}>
                Surviving Heirs
                </Text>
              </View>
              <View style={styles.cellShare}>
                <Text style={styles.tableText}>
                Share of the Remainder of My Estate
                </Text>
              </View>
            </View>

            {/* Render table rows */}
            {scheduleA_caseTwoTableData.map((row, index) => (
              <View style={styles.row} key={index}>
                <View style={styles.cellId}>
                  <Text style={styles.tableText}>{row.id}</Text>
                </View>
                <View style={styles.cellHeir}>
                  <Text style={styles.tableText}>{row.heirs}</Text>
                </View>
                <View style={styles.cellShare}>
                  <Text style={styles.tableText}>{row.share}</Text>
                </View>
              </View>
            ))}
          </View>
          <Text style={styles.textContent}>
              IF THE TESTATOR&rsquo;S CASE IS UNDER NO. 2 BUT NOT FOUND ABOVE, THE
            EXECUTOR MUST follow the advice of an Islamic Organization that adheres
            to the Qur&#39;an and authentic Sunnah of the Prophet(pbuh).
          </Text>







          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>CASE NO. 3:</Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>CHILDREN OF SON(S), NO SONS, NO DAUGHTERS</Text>
          </View>

          <Text style={styles.textContent}>
              Apply Case No.1 and Case No. 2 after substituting daughter(s) of son(s)
            for daughter(s) and son(s) of son(s) for son(s).
          </Text>


          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>CASE NO. 4:</Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>PARENT(S) AND NO OFFSPRING</Text>
          </View>

          <View style={styles.table}>
            {/* Render table header */}
            <View style={[styles.row, styles.headerCell]}>
            <View style={styles.cellId}>
                <Text style={styles.tableText}>
                  ID
                </Text>
              </View>
              <View style={styles.cellHeir}>
                <Text style={styles.tableText}>
                Surviving Heirs
                </Text>
              </View>
              <View style={styles.cellShare}>
                <Text style={styles.tableText}>
                Share of the Remainder of My Estate
                </Text>
              </View>
            </View>

            {/* Render table rows */}
            {scheduleA_caseFourTableData.map((row, index) => (
              <View style={styles.row} key={index}>
                <View style={styles.cellId}>
                  <Text style={styles.tableText}>{row.id}</Text>
                </View>
                <View style={styles.cellHeir}>
                  <Text style={styles.tableText}>{row.heirs}</Text>
                </View>
                <View style={styles.cellShare}>
                  <Text style={styles.tableText}>{row.share}</Text>
                </View>
              </View>
            ))}
          </View>

          <Text style={styles.textContent}>
          NOTE: IF THE TESTATOR&rsquo;S CASE IS UNDER NO. 4 BUT NOT COVERED
        ABOVE, THE EXECUTOR MUST follow the advice of an Islamic Organization that adheres
        to the Qur&#39;an and authentic Sunnah of the Prophet(pbuh).
          </Text>







          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>CASE NO. 5:</Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>HUSBAND OR WIFE, NO OFFSPRING, NO PARENTS, AND NO FATHER OF FATHER</Text>
          </View>

          <View style={styles.table}>
            {/* Render table header */}
            <View style={[styles.row, styles.headerCell]}>
            <View style={styles.cellId}>
                <Text style={styles.tableText}>
                  ID
                </Text>
              </View>
              <View style={styles.cellHeir}>
                <Text style={styles.tableText}>
                Surviving Heirs
                </Text>
              </View>
              <View style={styles.cellShare}>
                <Text style={styles.tableText}>
                Share of the Remainder of My Estate
                </Text>
              </View>
            </View>

            {/* Render table rows */}
            {scheduleA_caseFiveTableData.map((row, index) => (
              <View style={styles.row} key={index}>
                <View style={styles.cellId}>
                  <Text style={styles.tableText}>{row.id}</Text>
                </View>
                <View style={styles.cellHeir}>
                  <Text style={styles.tableText}>{row.heirs}</Text>
                </View>
                <View style={styles.cellShare}>
                  <Text style={styles.tableText}>{row.share}</Text>
                </View>
              </View>
            ))}
          </View>

          <Text style={styles.textContent}>
          NOTE: IF THE TESTATOR&rsquo;S CASE IS UNDER NO.5 BUT NOT COVERED ABOVE,
        THE EXECUTOR MUST follow the advice of an Islamic Organization that adheres to
        the Qur&#39;an and authentic Sunnah of the Prophet (pbuh).
          </Text>



          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>CASE NO. 6:</Text>
          </View>
          <View style={styles.imageView}>
            <Text style={styles.textHeadingH2}>ALL OTHER CASES</Text>
          </View>


          <Text style={styles.textContent}>
          Relatives not mentioned in cases (1) through (5) must be disregarded.
        However, I direct and ordain that all cases not specifically mentioned
        in this schedule shall be referred to an Islamic Organization that
        adheres to the Qur&#39;an and authentic Sunnah of the Prophet (pbuh) for
        distribution of estate.
          </Text>





          



        </View>
      
        <Text style={styles.footer} fixed>
        _____________________________________________________
        
        </Text>
        <Text style={styles.textBlankLine} fixed>
          <Text style={{ textAlign: "right", }}>Signature: ____________________________</Text>
        </Text>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
}
