import * as yup from 'yup';

export const validationSchema = [
  yup.object().shape({
    personal_province: yup.string().required('Required'),
    personal_name: yup.string().required('Required'),
    personal_address: yup.string().required('Required'),
  }),
  yup.object().shape({
    executor_main_name: yup.string().required('Required'),
    executor_main_address: yup.string().required('Required'),
    executor_backup_name: yup.string(),
    executor_backup_address: yup.string(),
  }),

  yup.object().shape({
    guardian_main_name: yup.string(),
    guardian_main_address: yup.string(),
    guardian_backup_name: yup.string(),
    guardian_backup_address: yup.string(),
  }),

  yup.object().shape({
    charities_array: yup.array().of(
      yup.object().shape({
        type: yup.string(),
        name: yup.string(),
        address: yup.string(),
        amount: yup.number().max(30),
      })
    ),
  }),

  // yup.object().shape({
  //   witness_1_name: yup.string().required('Required'),
  //   witness_1_address: yup.string().required('Required'),
  //   witness_2_name: yup.string().required('Required'),
  //   witness_2_address: yup.string().required('Required'),
  //   witness_3_name: yup.string(),
  //   witness_3_address: yup.string(),
  // }),

  yup.object().shape({
    //backup_type: yup.string().required('Required'),
    backup_charityPurpose: yup.string(),
    backup_charityName: yup.string().required('Required'),
    backup_charityAddress: yup.string(),
    backup_charityNumber: yup.string(),
  }),
];
