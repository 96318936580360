import { NavLink } from "react-router-dom";
import background from "../assets/pattern-white.svg";
import basira_logo from "../assets/basira_logo_full_black.svg";

export default function Footer() {
  return(
    <section className="bg-white overflow-hidden print:hidden" style={{backgroundImage: `url(${background})`, backgroundPosition: "center"}}>
      <div className="container px-4 mx-auto">
        <div className=" w-full flex flex-wrap lg:items-center pt-2 pb-5 -mx-4">
          <div className="w-full md:w-1/4 lg:w-auto px-4">
            <NavLink className="block mb-5 md:mb-0 max-w-max text-xl" to="/">
              freeislamicwill.ca
            </NavLink>
          </div>
          <div className="w-full md:w-3/4 lg:flex-1 px-4">
            <div className="flex flex-wrap justify-end -mx-3 lg:-mx-6">
              <div className="w-full md:w-auto p-3 md:py-0 md:px-6"><NavLink className="inline-block text-base text-coolGray-500 hover:text-coolGray-600 font-medium" to="/faq">FAQ</NavLink></div>
              <div className="w-full md:w-auto p-3 md:py-0 md:px-6"><NavLink className="inline-block text-base text-coolGray-500 hover:text-coolGray-600 font-medium" to="/donate">Donate</NavLink></div>
              {/* <div className="w-full md:w-auto p-3 md:py-0 md:px-6"><NavLink className="inline-block text-base text-coolGray-500 hover:text-coolGray-600 font-medium" to="/endorsements">Endorsements</NavLink></div> */}
              <div className="w-full md:w-auto p-3 md:py-0 md:px-6"><NavLink className="inline-block text-base text-coolGray-500 hover:text-coolGray-600 font-medium" to="/contact">Contact</NavLink></div>
            </div>
          </div>
        </div>
      </div>
      <div className="border-b border-coolGray-100"></div>
      <div className="container px-4 mx-auto">
        <div className=" w-full flex flex-wrap items-center pt-5">
          <div className="w-full md:w-1/2 mb-6 md:mb-0">
          <a href="http://basira.herolinks.ca" target="_blank">
          <img src={basira_logo} alt="Basira logo" className="h-10" /></a>
            
          <a href="http://basira.herolinks.ca" target="_blank">
          <p className="text-coolGray-400 font-medium">A Project of Basira</p></a>
          </div>
          <div className="w-full md:w-1/2">
            <div className="flex flex-wrap md:justify-end -mx-5">
              <div className="px-5">
                {/* <NavLink className="inline-block text-coolGray-300 hover:text-coolGray-400" to="https://facebook.com">
                  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.63494 17.7273V9.76602H9.3583L9.76688 6.66246H6.63494V4.68128C6.63494 3.78301 6.88821 3.17085 8.20297 3.17085L9.87712 3.17017V0.394238C9.5876 0.357335 8.59378 0.272728 7.43708 0.272728C5.0217 0.272728 3.3681 1.71881 3.3681 4.37391V6.66246H0.636475V9.76602H3.3681V17.7273H6.63494Z" fill="currentColor"></path>
                  </svg>
                </NavLink> */}
              </div>
              <div className="px-5">
                {/* <NavLink className="inline-block text-coolGray-300 hover:text-coolGray-400" to="https://www.twitter.com">
                  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.8181 2.14597C18.1356 2.44842 17.4032 2.65355 16.6336 2.74512C17.4194 2.27461 18.0208 1.5283 18.3059 0.641757C17.5689 1.07748 16.7553 1.39388 15.8885 1.56539C15.1943 0.824879 14.2069 0.363636 13.1118 0.363636C11.0108 0.363636 9.30722 2.06718 9.30722 4.16706C9.30722 4.46488 9.34083 4.75576 9.40574 5.03391C6.24434 4.87512 3.44104 3.36048 1.56483 1.05894C1.23686 1.61985 1.05028 2.27342 1.05028 2.97109C1.05028 4.29106 1.72243 5.45573 2.74225 6.13712C2.11877 6.11627 1.53237 5.94476 1.01901 5.65967V5.70718C1.01901 7.54979 2.33086 9.08761 4.07031 9.43761C3.75161 9.52336 3.41555 9.57088 3.06789 9.57088C2.82222 9.57088 2.58464 9.54655 2.35171 9.50018C2.8361 11.0125 4.24067 12.1123 5.90483 12.1424C4.6034 13.1622 2.96243 13.7683 1.1801 13.7683C0.873008 13.7683 0.570523 13.7498 0.272705 13.7162C1.95655 14.7974 3.95561 15.4278 6.10416 15.4278C13.1026 15.4278 16.928 9.63115 16.928 4.60397L16.9153 4.11145C17.6627 3.57833 18.3094 2.90851 18.8181 2.14597Z" fill="currentColor"></path>
                  </svg>
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}