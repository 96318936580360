import { Box, Flex } from "@chakra-ui/react";
import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import { Forms, TopHeading } from "../components/Forms";
import { useToast, Tooltip } from "@chakra-ui/react"

export default function Update() {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const toast = useToast()

  const readFileOnUpload = (uploadedFile) => {
    setSelectedFile(uploadedFile);
    setIsSelected(true);
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      try {
        localStorage.setItem(
          "formData",
          JSON.stringify(JSON.parse(fileReader.result))
        );
        localStorage.setItem("update", true);
      } catch (e) {
        toast({
          title: 'Not valid JSON file!, choose BackupWillInformation-{Date_Time}.json instead.',
          status: 'error',
          isClosable: true,
          duration: 6000,
          position: 'top'
        })
      }
    };
    if (uploadedFile !== undefined) fileReader.readAsText(uploadedFile);
  };

  return (
    <>
      <div className="mb-10 print:m-0 print:p-0">
        <Forms className="print:m-0 print:p-0">
          <Flex className="print:m-0 print:p-0" direction={"column"}>
            {/* <Flex className="flex-col m-0 justify-between p-2 md:p-6 rounded-lg border-[1px] min-h-[85vh] print:py-0 print:px-6 print:m-0"> */}
            <TopHeading
              heading="Upload Backup Will Information"
              //toolRef={toolRef}
              isDisabled={true}
            />
            <div class="max-w-xl w-full mx-auto print:max-w-6xl print:m-0 p-0 css-0">
              {/* <div class="rounded-lg"> */}
              <Box
                className="rounded-lg max-w-xl w-full mx-auto print:max-w-6xl print:m-0 p-0"
                //color='tertiary' <p>Filetype: {selectedFile.type}</p> <p>Size in bytes: {selectedFile.size}</p>
                maxW="100vw"
                borderColor={{ color: "primaryTint" }}
                borderWidth="1px"
                px="1em"
                pl={"2em"}
                pt={"2em"}
                mb="2em"
                minH={'450px'}
              >
                <div role="group" class="chakra-form-control">
                  <div class="flex flex-col">
                    <div
                      id="field-:rh:-helptext"
                      class="chakra-form__helper-text font-thin text-base css-186pyma"
                    >
                      Choose{" "}
                      <b style={{ fontWeight: "bold" }}>
                        BackupWillInformation-{`{Date_Time}`}.json
                      </b>{" "}
                      from your device to update Will information<br></br><br></br>
                      <input
                        type="file"
                        name="file"
                        onChange={(e) => readFileOnUpload(e.target.files[0])}
                        className={`{rounded-button font-thin text-sm md:text-base}`}
                        style={{ marginTop: "5px" }}
                      /><br></br><br></br>
                      
                      {isSelected ? (
                        <div>
                          <p><b style={{ fontWeight: "bold" }}>File name:</b> {selectedFile.name}</p>
                          <p>
                          <b style={{ fontWeight: "bold" }}>Date the backup taken:</b> 
                            {" "}
                            {console.log('selectedFile', selectedFile.lastModified)}
                            { selectedFile.lastModifiedDate !== undefined ? selectedFile.lastModifiedDate?.toLocaleString('en-US', { 
                              year: 'numeric', 
                              month: 'long', 
                              day: 'numeric', 
                              hour: 'numeric', 
                              minute: 'numeric', 
                              second: 'numeric', 
                              hour12: true 
                            }) : new Date(selectedFile.lastModified).toLocaleString('en-US', { 
                              year: 'numeric', 
                              month: 'long', 
                              day: 'numeric', 
                              hour: 'numeric', 
                              minute: 'numeric', 
                              second: 'numeric', 
                              hour12: true 
                            })}
                          </p>
                        </div>
                      ) : (
                        <div><br></br><br></br><p><b style={{ fontWeight: "bold" }}>Can't find it?</b></p>
                        <p>If you created your will after April 9th, you had the option to download a backup file of your will after downloading the will. If you did not download it your will data will be auto retrieved from your browser automatically. Just click on Create will from the same browser you created your will on last time.</p>
                        <br></br><p>If you created your will before April 9th, you will have to enter your data again manually.</p>
                        </div>
                      )}
                      <br></br>
                      {selectedFile?.type === "application/json" &&
                      selectedFile?.name?.includes("BackupWillInformation") ? (
                        <div className="w-1/2 xl:w-1/3">
                          <div className="xl:flex items-center justify-left">
                            <NavLink
                              style={{ color: "black" }}
                              className="inline-block py-2 px-4 text-sm leading-5 text-yellow-50 bg-yellow-500 hover:bg-yellow-600 font-medium focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 rounded-md"
                              to="/create"
                            >
                              Update Will
                            </NavLink>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                {/* <div style={{ minHeight: "275px" }}>&nbsp;</div> */}
              </Box>
              {/* </div> */}
            </div>
            {/* </Flex> */}
          </Flex>
        </Forms>
      </div>
      <Footer />
    </>
  );
}
