import React from 'react';
import { FormLabel } from '@chakra-ui/react';

export default function FormLabelHeading({ placeholder }) {
  return (
    <FormLabel className='text-amber-500 text-base md:text-lg'
      as='h3'
      // fontSize={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'sm' }}
      mb={'0'}>
      {placeholder}
    </FormLabel>
  );
}
