import React from 'react';
// import { EditIcon } from '@chakra-ui/icons';
import {
  useMediaQuery,
  Box,
  Flex,
  // Button,
  Spacer,
  // Heading,
  Image,
} from '@chakra-ui/react';
import Footer from "../components/Footer";


export default function Contact() {
  return (
    <div className="pt-20 pb-5 md:pt-28">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-4">
          <div className="md:w-2/3 px-4 mb-16 md:mb-0">
            {/* <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-white bg-yellow-500 uppercase rounded-3xl">Free Forever</span> */}
            <h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight">Contact us</h1>
            <p className="mb-8 text-lg md:text-xl text-coolGray-500 font-medium">If you have any comments or notice any issues with the will or website, or if you find any part unclear, don't hesitate to reach out to us at <b>freeislamicwill@basira.ca</b> for feedback and questions. However, please keep in mind that we are not legal professionals and will not be able to answer any legal inquiries.</p>            
          </div>
        </div>
      </div>
      <div className="mt-24"></div>
      <Footer />
    </div>
    


    // <div class="zeffyContainerDiv"><iframe title='Donation form powered by Zeffy' src='https://www.zeffy.com/en-CA/embed/donation-form/ab0a9b1e-aa78-409d-8da3-946a36d5dca2' allowpaymentrequest allowTransparency="true"></iframe></div>
  );
}
