import { Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
// import Header from './Header';
import Navbar from './Navbar';

export default function RootLayout() {
  return (
    <Flex className='max-w-7xl w-full min-h-full flex-col mx-auto p-4 rounded-md'>
      <Navbar />
      <Outlet />
    </Flex>
  );
}
