import React from 'react';
import { NameAddress, TopHeading, InputSelect } from '.';

export default function Personal({ toolRef }) {
  return (
    <>
      <TopHeading
        heading='Personal Information'
        toolRef={toolRef}
        isDisabled={true}
      />
      <InputSelect
        isRequired={true}
        name={'personal_province'}
        placeholder={'Province'}
        options={['Alberta', 'British Columbia', 'Ontario']}
        helperText={'We currently only support AB, BC and ON.'}
      />
      <NameAddress
        mainName={'personal'}
        isSubHeading={false}
        isRequired={true}
        isAddressRequired = {true}
        isAddressVisible={true}
        isEmailVisible={true}
        isPersonal={true}
      />
    </>
  );
}
