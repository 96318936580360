import React from "react";
import { PDFViewer } from '@react-pdf/renderer';
import WillDocument from "../components/PDF";

export default function Pdf() {
  return (
    <>
      <PDFViewer height={window.innerHeight} width="100%">
        <WillDocument />
      </PDFViewer>
    </>
  );
}
