import React from 'react';
import { NameAddress, TopHeading } from '.';

export default function Executors({ toolRef }) {
  return (
    <>
      <TopHeading
        heading='Executors'
        toolRef={toolRef}
      />
      <NameAddress
        mainName={'executor_main'}
        heading='Main Executor'
        isRequired={true}
        isAddressRequired={true}
        isAddressVisible={true}
        helperText={'We currently only support AB, BC and ON.'}
      />
      <NameAddress
        mainName={'executor_backup'}
        heading='Backup Executor (Optional)'
        isAddressVisible={true}
      />
    </>
  );
}
