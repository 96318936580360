import React from 'react';
import { Flex, Heading, IconButton } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

export default function TopHeading({ heading, toolRef, isDisabled = false }) {
  return (
    <Flex
      alignItems={'center'}
      my='1em'>
      <h3 id="topHeading" tabIndex="0" className='w-full text-center font-extrabold text-lg md:text-xl print:hidden'
        // as='h3'
        // w='100%'
        // textAlign={'center'}
        // fontWeight='extrabold'
        // fontSize={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'sm' }}
        >
        {heading}
      </h3>
      <IconButton className='text-amber-400 print:hidden'
        onClick={() => toolRef.current.scrollIntoView()}
        display={isDisabled ? 'none' : 'block'}
        icon={<InfoIcon />}
      />
    </Flex>
  );
}
