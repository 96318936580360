import React from 'react';
import { TopHeading, InputSelect, InputText, NameAddress } from '.';
import { useFormikContext } from 'formik';

export default function Backup({ toolRef }) {
  const { values } = useFormikContext();
  return (
    <>
      <TopHeading
        heading='Backup Beneficiary Charity'
        toolRef={toolRef}
      />
      {/* <InputSelect
        name={`backup_type`}
        placeholder='Organization or Individual'
        isRequired={true}
        options={['Organization', 'Individual']}
      /> */}
      <InputText
        name='backup_charityName'
        isRequired={true}
        placeholder='Charity Name'
        helperText={`The charity name you want to receive any left over estate as backup.`}
      />

      <InputText
        name='backup_charityAddress'
        isRequired={false}
        placeholder='Charity Address'
        helperText={`Address of the Charity (needed only for overseas charities)`}
      />

      <InputText
        name='backup_charityNumber'
        isRequired={false}
        placeholder='Charity Number'
        helperText={`Charity registeration number with CRA. (needed only for local charities)`}
      />

      <InputText
        name='backup_charityPurpose'
        isRequired={false}
        placeholder='Purpose'
        helperText={`If you want the money to be used as general sadaqa, you can leave it blank.`}
      />
      
      {/* <NameAddress
        mainName={'backup'}
        isRequired={true}
        isAddressRequired={false}
        isAddressVisible={true}
        isSubHeading={false}
      /> */}
    </>
  );
}
