import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

export default function TextPara({
  // color = 'secondary',
  // fontSize = '7px',
  textAlign = 'justify',
  containerStyle = {},
  gap = 1,
  children,
  ...props
}) {
  return (
    <Flex
      direction={'column'}
      gap={gap}
      {...containerStyle}>
      {children.split('\n').map((line, index) => (
        <Box
          as='p'
          textAlign={textAlign}
          // color={color}
          // fontSize={fontSize}
          {...props}
          key={`text-${index}`}
          __css={{
            '&:first-letter': {
              textTransform: 'capitalize',
            },
          }}>
          <div dangerouslySetInnerHTML={{__html: line}} />
        </Box>
      ))}
    </Flex>
  );
}
