import React from 'react';
import { FormControl, Select } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useFormikContext } from 'formik';
import { FormHelper, FormLabelHeading } from '.';

export default function InputSelect({
  name,
  isRequired = false,
  placeholder,
  options,
  helperText,
  width,
  my = '1em',
  isCharity = false,
  fieldName,
  ...props
}) {
  const { setFieldTouched, setFieldValue, handleChange, touched, values } =
    useFormikContext();
  return (
    <FormControl
      isInvalid={
        isRequired &&
        touched[isCharity ? `${fieldName}` : name] &&
        values[isCharity ? `${fieldName}` : name] === ''
      }
      // border='tertiary'
      isRequired={isRequired}
      my='my'
      width={width}>
      <FormLabelHeading placeholder={placeholder} />
      <Select className='placeholder:text-black placeholder:italic text-base md:text-lg'
        defaultValue={values[isCharity ? `${fieldName}` : name]}
        onBlur={() => setFieldTouched(isCharity ? `${fieldName}` : name)}
        onChange={(e) => {
          setFieldValue(isCharity ? `${fieldName}` : name, e.target.value);
          handleChange(isCharity ? `${fieldName}` : name);
        }}
        // onChangeText={(val) => {
        //   setFieldValue(isCharity ? `${fieldName}` : name, val);
        // }}
        variant='filled'
        icon={<ChevronDownIcon />}
        fontWeight={'thin'}
        // fontSize={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'sm' }}
        // _placeholder={{ opacity: 0.3, color: 'tertiary' }}
        // color='tertiary'
        // bg={'step950'}
        // borderColor='step850'
        borderWidth='0.3px'
        // focusBorderColor='tertiary'
        rounded='md'
        width={width}
        {...props}>
        <option value=''>Select</option>
        {options.map((item, i) => (
          <option key={`${name}_${i}`}>{item}</option>
        ))}
      </Select>
      <FormHelper helperText={helperText} />
    </FormControl>
  );
}
