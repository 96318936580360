export const faqInfo = [
  {
    question: 'What is FreeMuslimWill.ca?',
    answer: `Basira Foundation's Islamic wills service is a free website designed to assist Muslims residing in Canada in fulfilling their obligation of having an Islamic will. Our initiative strives to make this process free or as cheap as it can possibly be. All the templates have been reviewed by a lawyer to make sure that they work and hold in the provinces we support.`,
  },
  {
    question: 'Do I need a Will?',
    answer: `Having a will is crucial as it ensures your estate is distributed according to your wishes and meets Islamic law, prevents delays in providing financial support for your family, and allows you to exercise your right to give away a third of your money to anyone or charity of your choosing.`,
    long_answer: `Making a will is important for many reasons, here are some of them:

    1. If you do not have a will the government will distribute your estate based on their rules and regulations which does not meet the Islamic law. 
    
    2. If there is no will distributing the estate might take time which will leave your family without financial support until the estate is distributed.
    
    3. If both parents die at the same time and they have minors, without a will that has a legal guardian in it, the minors might be put in a shelter even if there are Muslim friends willing to take them. The minors might stay there until the court decides who is the guardian which can take some time.
    
    4. In Islam you have the right to give away third of your money to anyone (that meet the conditions) or any charity you want. You are passing on this opportunity if you do not have a will.`
  },
  {
    question: 'Are there any additional fees to maintain or update my Will?',
    answer: `No, the website is 100% free! And we are planning to add more and more important features, stay tuned!`,
  },
  {
    question: 'Do I still need a Will even if I do not have a family?',
    answer: `It is important to follow Islamic rules for inheritance, even if you do not have immediate family, and consulting with a scholar is recommended. Utilise the option to give a third of your money to charity, and create a will to prevent the government from taking your estate.`,
  },
  {
    question: 'When do I need to change my Will?',
    answer: `The advantage of this will website is that the will is valid for most changes in your personal situation. However, you will still need to get a new will if:\n
        1. Both the primary and secondary executor die or leave the country.
        2. Both the primary and secondary guardians die or leave the country.
    
    Generating a new will is as easy as the first time!
    `,
  },
  {
    question: 'Why should I write an Islamic Will/Wasiyyah?',
    answer: `There is much evidence in the Quran and Sunnah, showing the importance of Islamic Will/Wassiyah. 
    
    The Prophet Muhammad (may peace be upon him) ordered all Muslims to prepare a Last Will & Testament.`,
  },
  {
    question: 'As a Muslim lives in a non-Muslim country, do I need a Will and Why Muslim Will?',
    answer: `Plan your will in advance if you're a Muslim living in a non-Muslim country to protect your family and assets, and to ensure fair distribution of inheritance according to Islamic law. The Prophet (PBUH) emphasized the significance of having a will, stating that it's the duty of every Muslim to not let two nights pass without writing one.`,
  },
  {
    question: 'Why do main scholars in Islam have different opinions about estate divisions?',
    answer: `Even though Islam covers the laws of inheritance in detail, not every heir (inheritor) situation is listed within the Quran and Sunnah. In these instances, Muslim scholars interpret the estate division to the best of their ability which leads to different perspectives on the matter. However, the majority of the scholars are in agreement with each other about most situations.`,
  },
  {
    question: 'How are inheritance shares calculated in a Muslim Will?',
    answer: `Inheritance shares are calculated according to the rules and principles found in the Quran and the Sunnah, and the interpretations of Muslim scholars.`,
  },
  {
    question: 'What is a bequest in Islamic Will?',
    answer: `A bequest (also called: Charitable Contributions and Testamentary Transfer) is a certain amount of money and property given to non-heirs (people who are not recognized as potential inheritors) i.e., adopted children, charities, etc. In Islam it can not be more than a third of the estate.`,
  },
  {
    question: 'Why is a bequest in Islamic Will limited to one-third of a person’s estate?',
    answer: `A person cannot bequeath more than one third of their estate because it’s the limit set by the Prophet when Saad Ibn Abi-Waqqas approached him about giving two-thirds of his money to charity. The Prophet rejected the two-third donation, prompting Saad to suggest one-half, which the Prophet also refused. “One-third?” asked Saad. The Prophet accepted hesitantly, saying “A third then a third is still too much.”`,
  },
  {
    question: 'Why don\'t I have to enter any of my family members in the will?',
    answer: `Our will is designed and reviewed by lawyers to be written once and no updates will be required after. The will make sure that:
    
      1. Your estate will be distributed according to the Islamic law depending on your family members at the time of death.
      2. Your third of the estate will go as you direct in the will.
      3. If both parents die at the same time the kids will be taken care of by the guardian you specify.`,

  },
  {
    question: 'When should I NOT use your service?',
    answer: `Our service is designed to work with any Muslim who wants their estate to be distributed according to the Islamic law and/or protect their kids from having to go to a shelter after they die. However, there are some situations where you should not use this service, some of those are: \n
    1. If your family situation is complicated in Canada and you think a spouse or a kid or a kid of a spouse might challenge the will. In this case it is better to do the will with a lawyer to minimise the chance of your will being challenged.
    2. If you want to add specific extra wishes and you want to have in the will itself. We currently do not support that.
    
    Most importantly: This will is better than having no will at all. So even if the above applies to you, you should create a free will with us today then work on creating a tailored will. Don't let Shaitan distract you from making a will.
    `,

  },
  {
    question: 'Has this been approved by a lawyer?',
    answer: `Yes, we consulted a lawyer about the content of the will and made sure it is enforceable.`,

  },
  {
    question: 'What should I share with the executor?',
    answer: `
      1. Share the location of the original will (or you can keep it with them if they are trusted and live in the same city).\n
      2. Share your family context, like how many siblings and children you have.\n
      3. Get them a few contact numbers to reach your family (including family home) when needed.\n
      4. Consider sharing your inventory of assets or keeping it in a location that can be easily accessed by the executor of family when the time comes. This will simplify matters for those who will handle your affairs after you're gone.
    `,

  },
  {
    question: 'I care about my privacy. What data do you store?',
    answer: `
    The only piece of data we store is your email address. No other data you enter leaves your device. \n
    We store your email address in case we want to keep you updated about changes to the laws or other important information that might require you to update your will.
    `,

  },
  {
    question: 'What provinces do you currently support?',
    answer: `
    We currently support Ontario, British Columbia and Alberta. We will work on adding more provinces in shaa' Allah.
    `,

  },
  {
    question: 'When will you support the rest of the provinces?',
    answer: `
    To support a new province we will need a lawyer from that province to take a look at our will to make sure it is enforcable in that province and to help us write the instructions document. If you are a lawyer in a non-supported province reach out to us.
    `,

  },
  {
    question: 'Who is behind this?',
    answer: `
    Basira Islamic Foundation (BIF) is a registered charity in Canada. One of our main focuses is to serve Muslims using digital free projects. Know about our other projects and support us from <u><a target="_blank" href="https://basira.herolinks.ca">here</a></u>.
    `,

  }
];
