import React from 'react';
import { FormErrorMessage, FormHelperText } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

export default function FormHelper({ helperText }) {
  const { errors, touched } = useFormikContext();
  return (
    <div className='flex flex-col'>
      <FormHelperText className='font-thin text-base'
        // fontWeight={'thin'}
        // fontSize='0.5em'
        // color='secondaryTint'
        // mt='0.1em'
        >
        {helperText}
      </FormHelperText>
      {errors && touched && (
        <FormErrorMessage className='font-thin text-base'
          // fontWeight={'thin'}
          // fontSize='0.5em'
        >
          Field is required
        </FormErrorMessage>
      )}
    </div>
  );
}
