import React from 'react';
import CardItem from './CardItem';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './styles.css';
import { Pagination, Navigation, Mousewheel, Keyboard } from 'swiper';
import { endoresementInfo } from '../../assets/edorsementInfo';
import { Flex, Heading, Avatar } from '@chakra-ui/react';
import background from "../../assets/pattern-white.svg";
import quoteTopYellow from '../../assets/quote-top-yellow.svg';
import quoteDownYellow from '../../assets/quote-down-yellow.svg';

export default function CardSwiper() {
  return (
    <section className="py-10 md:pb-12 bg-white" style={{backgroundImage: `url(${background})`, backgroundPosition: "center"}} id="endorsements">
      <div className="container px-4 mx-auto">
      <Swiper
        style={{
          '--swiper-navigation-size': '1rem',
        }}
        cssMode={true}
        navigation={true}
        pagination={{ clickable: true }}
        mousewheel={true}
        loop={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className='mySwiper'>
        {endoresementInfo.map((item, index) => (
          <SwiperSlide key={`cardSwiperItem-${index}`}>
            <div>
            <div className="relative max-w-4xl sm:px-6 pb-6 mb-5 mx-auto text-center">
              <img className="absolute top-0 left-0" src={quoteTopYellow} alt="islamicwill.ca"/>
              <img className="absolute bottom-0 right-0" src={quoteDownYellow} alt="islamicwill.ca"/>
              <div className="relative">
                <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-yellow-500 bg-yellow-100 font-medium uppercase rounded-9xl">Our Endorsement(s)</span>
                <h2 className="text-2xl md:text-3xl font-semibold tracking-tighter">{item.testimony}</h2>
              </div>
            </div>
            <div className="text-center mb-8">
              <Avatar
                className='w-24 h-24 mx-auto mb-6 rounded-full'
                src={item.src && item.src}
                width="96px"
                height="96px"
                  // bg='primaryShade'
                />
              <h3 className="mb-2 text-xl md:text-2xl font-semibold">{item.name.trim()}</h3>
              <span className="text-lg text-coolGray-500 font-medium whitespace-pre-line">{item.position}</span>
            </div>
            </div>
          </SwiperSlide>
        ))}       
      </Swiper>
      </div>
    </section>
    // {/* <Flex className='bg-yellow-50'
    //   direction={'column'}
    //   align={'center'}
    //   justify={'center'}
    //   w={'100%'}
    //   h={'100%'}
    //   borderRadius={'xl'} 
    //   // bg={'rgba(115, 73, 34, 0.05)'}
    //   // boxShadow={'0 0 10px tertiary'}
    //   >
    //   <Heading
    //     position={'relative'}
    //     bottom={'-1em'}
    //     as='h5'
    //     fontWeight={'thin'}
    //     textAlign='center'
    //     color='tertiary'
    //     fontSize='lg'
    //     >
    //     Our Endorsements
    //   </Heading>
    //   <Swiper
    //     style={{
    //       '--swiper-navigation-size': '1rem',
    //     }}
    //     cssMode={true}
    //     navigation={true}
    //     pagination={true}
    //     mousewheel={true}
    //     loop={true}
    //     keyboard={true}
    //     modules={[Navigation, Pagination, Mousewheel, Keyboard]}
    //     className='mySwiper'>
    //     {endoresementInfo.map((item, index) => (
    //       <SwiperSlide key={`cardSwiperItem-${index}`}>
    //         <CardItem
    //           item={item}
    //           isSwipper={true}
    //         />
    //       </SwiperSlide>
    //     ))}
    //   </Swiper>
    // </Flex> */}
  );
}
