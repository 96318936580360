import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { InputGroup, useMediaQuery, Button, Box, Text } from '@chakra-ui/react';
import { MinusIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { InputSelect, InputText, TopHeading } from '.';

export default function Charities({ toolRef }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { values } = useFormikContext();
  return (
    <>
      <TopHeading
        heading='Charitable Contributions and Testamentary Transfer (Optional)'
        toolRef={toolRef}
      />
      <FieldArray
        name='charities_array'
        render={(arrayHelpers) => (
          <>
            {values.charities_array.map((charity, index) => (
              <Box
                key={`charities_array[${index}]`}
                display='flex'
                // border='tertiary'
                flexDirection={'column'}
                // color='tertiary'
                // borderColor={{ color: 'textContrast' }}
                // borderWidth={isMobile ? '5px' : '0px'}
                // gap={isMobile ? '0' : '5'}
                p='1em'
                my='1em'
                justifyContent={isMobile ? 'center' : 'space-evenly'}
                alignItems={isMobile ? 'center' : 'space-evenly'}>
                <>
                  <InputGroup className='rounded-xl border '
                    key={`charities_array_Input[${index}]`}
                    display='flex'
                    // border='primaryTint'
                    // flexDirection={isMobile ? 'column' : 'row'}
                    flexDirection={'column'}
                    // color='tertiary'
                    // borderColor={{ color: 'textContrast' }}
                    // borderWidth= '2px' //{isMobile ? '5px' : '0px'}
                    // gap={isMobile ? '0' : '5'}
                    p='1em'
                    justifyContent={isMobile ? 'center' : 'space-evenly'}
                    alignItems={isMobile ? 'center' : 'space-evenly'}>
                    <InputSelect
                      key={`charities_array_type_${index}`}
                      name={`charities_array[${index}].type`}
                      options={['Charity', 'Individual']}
                      value={charity && charity.type}
                      width={'max-content'}
                      my='0'
                      isCharity={true}
                      fieldName={`charities_array.${index}.type`}
                    />
                    <InputText
                      key={`charities_array_name_${index}`}
                      name={`charities_array[${index}].name`}
                      placeholder='Charity/Individual Name'
                      isLabel={false}
                      value={charity && charity.name}
                      my={isMobile ? '0.5em' : '0'}
                      isCharity={true}
                      fieldName={`charities_array.${index}.name`}></InputText>
                    <InputText
                      key={`charities_array_address_${index}`}
                      name={`charities_array[${index}].address`}
                      placeholder='Charity/Individual Address'
                      helperText={"Needed for individuals or overseas charities."}
                      isLabel={false}
                      value={charity && charity.address}
                      my={isMobile ? '0.5em' : '0'}
                      isCharity={true}
                      fieldName={`charities_array.${index}.address`}></InputText>
                    
                    {
                      
                      values['charities_array'][index]['type']==='Charity' &&
                        (<InputText
                          key={`charities_array_charityNumber_${index}`}
                          name={`charities_array[${index}].charityNumber`}
                          placeholder='Charity Number'
                          helperText={"Charity registeration number with CRA. (needed only for local charities)"}
                          isLabel={false}
                          value={charity && charity.charityNumber}
                          my={isMobile ? '0.5em' : '0'}
                          isCharity={true}
                          fieldName={`charities_array.${index}.charityNumber`}></InputText>) 
                    }
                    {values['charities_array'][index]['type']==='Charity' &&

                        (<InputText
                          key={`charities_array_purpose_${index}`}
                          name={`charities_array[${index}].purpose`}
                          placeholder='Purpose'
                          isLabel={false}
                          value={charity && charity.purpose}
                          my={isMobile ? '0.5em' : '0'}
                          helperText={"If you want the money to be used as general sadaqa, you can leave it blank."}
                          isCharity={true}
                          fieldName={`charities_array.${index}.purpose`}></InputText>)

                    }

                    
                    <InputText
                      key={`charities_array_percentage_${index}`}
                      name={`charities_array[${index}].percentage`}
                      placeholder='Enter Percentage'
                      //type='number'
                      //max={33.3}
                      isLabel={false}
                      width={isMobile ? null : '20vw'}
                      value={charity && charity.percentage}
                      my='0'
                      isCharity={true}
                      fieldName={`charities_array.${index}.percentage`}></InputText>
                    <Button
                      // borderColor='tertiary'
                      variant='outline'
                      mt={isMobile ? '1em' : '0.5em'}
                      onClick={() => {
                        arrayHelpers.remove(index);
                        // console.log(values.charities_array.length);
                        if (values.charities_array.length === 1) {
                          arrayHelpers.push({
                            type: '',
                            name: '',
                            address: '',
                            charityNumber: '',
                            purpose: '',
                            percentage: '',
                          });
                        }
                      }}
                      _focus={{ boxShadow: 'none' }}
                      boxShadow='xs'
                      fontWeight={'thin'}
                      fontSize={'0.6em'}
                      size='xs'
                      rounded={'md'}
                      // color='tertiary'
                      alignSelf={'center'}
                      w='fit-content'>
                      <Text fontSize={'1.2em'}>
                          Reset
                        </Text>
                    </Button>
                  </InputGroup>
                  <Text fontSize={'0.7em'}>
                          Once you fill type, name and percentage above you can add another charity.
                        </Text>
                  {index === values.charities_array.length - 1 &&
                    charity.type &&
                    charity.name &&
                    
                    charity.percentage && (
                      <Button className='text-base'
                        // borderColor='tertiary'
                        borderWidth={'thin'}
                        variant='outline'
                        mt='0.5em'
                        onClick={() => {
                          arrayHelpers.push({
                            type: '',
                            name: '',
                            address: '',
                            charityNumber: '',
                            purpose: '',
                            percentage: '',
                          });
                        }}
                        _focus={{ boxShadow: 'none' }}
                        boxShadow='lg'
                        fontWeight={'thin'}
                        // size='xs'
                        rounded='md'
                        // color='tertiary'
                        alignSelf={'center'}
                        w='fit-content'
                        leftIcon={<PlusSquareIcon />}>
                        <Text fontSize={'0.7em'}>
                          Add {values.charities_array.length ? 'another ' : 'an '}
                          entity
                        </Text>
                      </Button>
                    )}
                </>
              </Box>
            ))}
          </>
        )}
      />
    </>
  );
}
