import { SimpleGrid, Heading, Flex, Text, Box } from '@chakra-ui/react';
import React from 'react';
import TopHeading from './TopHeading';
import { useFormikContext } from 'formik';
import { formValues } from './formValues';

function PreviewFields({ data }) {
  const displayValue = Array.isArray(data.value) ?
    Object.values(data).some(obj => Object.values(obj).some(val => val !== '')) ?
    <DisplayData data={data.value} /> :
    <div className='text-slate-600'>None</div> : 
    data.value === undefined || data.value === null || data.value === '' ? <div className='text-slate-600'>None</div> :
    <div className='text-slate-600'>{JSON.parse(JSON.stringify(data.value))}</div>

  return (
    <SimpleGrid pl={'1em'} columns={2} spacing={5}>
      <Text className='w-full font-normal text-base md:text-lg mb-1 print:mb-0 print:text-base'>{data.subHeading}</Text>
      <Box className='text-normal text-base md:text-lg'>{displayValue}</Box>
    </SimpleGrid>
  );
}

function DisplayData({ data }) {
  const dataItems = data.map(item => (
    <div key={item.name}>
      <p><strong>Type:</strong> {item.type === undefined || item.type === null || item.type === '' ? <span className='text-slate-600'>None</span> : item.type}</p>
      <p><strong>Name:</strong> {item.name === undefined || item.name === null || item.name === '' ? <span className='text-slate-600'>None</span> : item.name}</p>
      <p><strong>Address:</strong> {item.address === undefined || item.address === null || item.address === '' ? <span className='text-slate-600'>None</span> : item.address}</p>
      <p><strong>CharityNumber:</strong> {item.charityNumber === undefined || item.charityNumber === null || item.charityNumber === '' ? <span className='text-slate-600'>None</span> : item.charityNumber}</p>
      <p><strong>Purpose:</strong> {item.purpose === undefined || item.purpose === null || item.purpose === '' ? <span className='text-slate-600'>None</span> : item.purpose}</p>
      <p><strong>Percentage:</strong> {item.percentage === undefined || item.percentage === null || item.percentage === '' ? <span className='text-slate-600'>None</span> : item.percentage + "%"}</p>
    </div>
  ));

  return (
    <div>{dataItems}</div>
  );
}

function PreviewSub({ data }) {
  const subheading = data.subHeading;

  return (
    <Flex direction='column'>
      <h6 className='w-full font-bold text-base md:text-lg mb-1 print:mb-0 text-slate-600 print:text-base'>{subheading}</h6>
      {Object.entries(data.value).map(([key, value]) => (
        <PreviewFields key={key} data={value} />
      ))}
    </Flex>
  );
}

function PreviewMain({ data }) {
  const heading = data.heading;
  const isSubheading = data.isSubheading;
  const dataEntries = Object.entries(data.data);

  return (
    <Flex direction='column'>
      <h5 className='font-bold w-full text-base md:text-lg text-amber-400 mb-1 mt-2 border-b print:mt-0 print:mb-0 print:text-base'>{heading}</h5>
      {isSubheading ? (
        <>
          {dataEntries.map(([key, value]) => (
            <PreviewSub key={key} data={value} />
          ))}
        </>
      ) : (
        <>
          {dataEntries.map(([key, value]) => (
            <PreviewFields key={key} data={value} />
          ))}
        </>
      )}
    </Flex>
  );
}

function sortValues(values) {
  Object.keys(values).forEach((key) => {
    const main = key.substring(0, key.indexOf('_'));
    const sub = key.substring(key.indexOf('_') + 1, key.lastIndexOf('_'));

    if (formValues[main].isSubheading) {
      formValues[main].data[sub].value[key].value = values[key];
    } else {
      formValues[main].data[key].value = values[key];
    }
  });
}

export default function Preview() {
  const { values } = useFormikContext();
  sortValues(values);

  return (
    <>
      <TopHeading className='print:mt-0 print:hidden' heading='Confirm details' info='' isDisabled={true} />
      <div className='mb-10 print:mb-0'>
        {Object.entries(formValues).map(([key, value]) => (
          <PreviewMain key={key} data={value} />
        ))}
      </div>
    </>
  );
}
