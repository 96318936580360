import React from 'react';
import { FormControl, Input } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import { FormHelper, FormLabelHeading } from '.';

export default function InputText({
  name,
  isRequired = false,
  isLabel = true,
  placeholder,
  helperText,
  type = 'text',
  width,
  my = '1em',
  isCharity = false,
  fieldName,
  max = '',
  ...props
}) {
  const { setFieldTouched, setFieldValue, handleChange, touched, values } =
    useFormikContext();

  const isPercentage = type === 'number';

  return (
    <>
      <FormControl
        isInvalid={
          isRequired &&
          touched[isCharity ? `${fieldName}` : name] &&
          values[isCharity ? `${fieldName}` : name] === ''
        }
        isRequired={isRequired}
        border='tertiary'
        my={my}
        width={width}>
        <FormLabelHeading placeholder={placeholder} />
        <Input className='placeholder:text-slate-400/70 placeholder:italic placeholder:text-sm'
          defaultValue={values[isCharity ? `${fieldName}` : name]}
          onBlur={() => setFieldTouched(isCharity ? `${fieldName}` : name)}
          onChange={(e) => {
            setFieldValue(isCharity ? `${fieldName}` : name, e.target.value);
            handleChange(isCharity ? `${fieldName}` : name);
          }}
          // onChangeText={(val) => {
          //   setFieldValue(isCharity ? `${fieldName}` : name, val);
          // }}
          placeholder={placeholder}
          variant='filled'
          _placeholder={{
            // opacity: 0.3,
            // color: 'tertiary',
            // fontSize: '0.7em',
          }}
          // fontSize={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'sm' }}
          // color='tertiary'
          // bg={'step950'}
          // borderColor='step850'
          borderWidth='0.3px'
          // focusBorderColor='tertiary'
          type={type}
          width={width}
          max={isPercentage ? max : null}
          {...props}
        />
        {isPercentage && (
          <FormHelper
            helperText={`Please enter a value between 0 and ${max}%`}
          />
        )}
        {!isPercentage && <FormHelper helperText={helperText} />}
      </FormControl>
    </>
  );
}
