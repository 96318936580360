import { Accordion } from '@chakra-ui/react';
import React from 'react';
import { AccordionBox } from '../Shared';

export default function FormAccordion({ ref, description }) {
  return (
    <Accordion
      allowToggle
      width={'100%'}>
      <AccordionBox
        heading='Learn more on how to fill this section?'
        description={description}
        headingStyle={{ alignItems: 'center' }}
      />
    </Accordion>
  );
}
