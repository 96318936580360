import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { InputText } from '.';

function SubHeading({ heading, children }) {
  return (
    <>
      <h5 className='text-base md:text-lg'
        // as='h5'
        // w='100%'
        // fontWeight='normal'
        // fontSize={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'sm' }}
        mb='0.2em'>
        {heading}
      </h5>
      <Box className='rounded-lg'
        // color='tertiary'
        maxW='100vw'
        // borderColor={{ color: 'primaryTint' }}
        borderWidth='1px'
        px='1em'
        mb='2em'>
        {children}
      </Box>
    </>
  );
}

function ChildNameAddress({ isRequired, isAddressRequired, isAddressVisible, isEmailVisible, isPersonal, mainName }) {
  return (
    <>
      <InputText
        name={`${mainName}_name`}
        isRequired={isRequired}
        placeholder={'Full name'}
        helperText={`Must match ${
          isPersonal ? 'your' : 'their'
        } ID or passport document${isPersonal ? '.' : '. Last section explains more.'}`}
      />
      {
        isAddressVisible ? 
        <InputText
          name={`${mainName}_address`}
          isRequired={isAddressRequired}
          placeholder={'Full Address'}
          helperText={` ${
            isPersonal ? 'Your' : 'Their'
          } current legal residence.`}
          info={`Must match ${
            isPersonal ? 'your' : 'their'
          } ID or passport document. Remember that you have to update your will if ${
            isPersonal ? 'you' : 'they'
          } changed ${isPersonal ? 'your' : 'their'} address.`}
        /> : <div></div>
      }
      {
        isEmailVisible ? 
        <InputText
          type={'email'}
          name={`${mainName}_email`}
          isRequired={true}
          placeholder={'Email Address'}
          helperText={` ${
            isPersonal ? 'Your' : 'Their'
          } email address so that we can notify you of changes required in your will if required.`}
          info={`Must match ${
            isPersonal ? 'your' : 'their'
          } ID or passport document. Remember that you have to update your will if ${
            isPersonal ? 'you' : 'they'
          } changed ${isPersonal ? 'your' : 'their'} address.`}
        /> : <div></div>
      }
    </>
  );
}

export default function NameAddress({
  heading,
  isRequired = false,
  isAddressRequired = false, 
  isAddressVisible = false,
  isSubHeading = true,
  isPersonal = false,
  isEmailVisible = false,
  mainName,
}) {
  return (
    <>
      {isSubHeading ? (
        <SubHeading heading={heading}>
          <ChildNameAddress
            mainName={mainName}
            isRequired={isRequired}
            isAddressRequired={isAddressRequired}
            isAddressVisible={isAddressVisible}
            isEmailVisible={isEmailVisible}
            isPersonal={isPersonal}
          />
        </SubHeading>
      ) : (
        <ChildNameAddress
          mainName={mainName}
          isRequired={isRequired}
          isAddressRequired={isAddressRequired}
          isAddressVisible={isAddressVisible}
          isEmailVisible={isEmailVisible}
          isPersonal={isPersonal}
        />
      )}
    </>
  );
}
