import { ButtonGroup, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useFormikContext } from 'formik';
import React from 'react';
import { ButtonItem } from '../Shared/.';

//JAZ TODO: To fix the mandatory fields error problem:
//Next button has to be different in each component.
// Conditions etc..
export default function FormButton({ step, setStep, progress, setProgress }) {
  const { submitForm } = useFormikContext();
  const { values } = useFormikContext();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  
  // function _submitForm(values, actions) {
  //   setStep(step + 1);
  //   if (step === 6 || step === 7) {
  //     setProgress(100);
  //   } else {
  //     setProgress(progress + 19);
  //   }
  // }

  // function _handleSubmit(values, actions) {
  //   if (step===7) {
  //     _submitForm(values, actions);
  //   } else {
  //     setActiveStep(activeStep + 1);
  //     actions.setTouched({});
  //     actions.setSubmitting(false);
  //   }
  // }

  return (
    <div className='max-w-xl w-full mx-auto print:hidden'
      // mt='5%'
      // w='100%'
      >
      <Flex className='w-full items-center print:hidden'
        // w='100%'
        // direction={isMobile && step !== 7 ? 'column' : 'row'}
        // justifyContent='space-between'
        >
        <Flex>
          <ButtonItem className='text-base mr-3'
            onClick={() => {
              setStep(step - 1);
              step !== 7 && setProgress(progress - 19);
              document.getElementById('topHeading')?.focus();
            }}
            isDisabled={step === 1}
            isHidden={step === 1}
            // mr='5%'
            >
            Back
          </ButtonItem>

          {(
            <ButtonItem className='bg-amber-400  print:hidden'
              type='submit'
              // bg='bg-green-300'
              isDisabled={step === 7}
              isHidden={step === 7}
              onClick={(e) => {
                step !== 0 && localStorage.setItem('formData', JSON.stringify(values));
                document.getElementById('topHeading')?.focus();
              }}
              // onClick={() => {
              //   setStep(step + 1);
              //   if (step === 6 || step === 7) {
              //     setProgress(100);
              //   } else {
              //     setProgress(progress + 19);
              //   }
              // }}
              // bg='primary'
              // colorScheme='bg-amber-400'
              // borderColor='tertiary'
              // _click={{ bg: 'tertiary' }}
              // color='tertiary'
              variant='outline'
              >
              {step === 6 ? 'Submit  ' : 'Next'}
            </ButtonItem>
          )}
        </Flex>
        <div className="grow"></div>
        {step < 6 && (
          <Text className='text-base print:hidden'
            // mt={isMobile ? '1em' : '0'}
            // fontSize={'0.5em'}
            alignSelf='center'
            fontWeight='thin'
            // color='secondary'
            >
            Step {step} of 5
          </Text>
        )}
        {step === 8 ? (
          <ButtonItem onClick={submitForm}>Next</ButtonItem>
        ) : null}
      </Flex>
    </div>
  );
}
