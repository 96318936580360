import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  useDisclosure,
  Image,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import React from 'react';
import Navbar from './Navbar';
import source from '../assets/will.png';
import { ButtonItem } from './Shared/.';
import { NavLink } from 'react-router-dom';
import basira_logo from "../assets/basira_icon_black.svg";

export default function SideDrawer(props) {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Drawer finalFocusRef={btnRef} className="navbar-menu hidden fixed top-0 left-0 w-full h-full bg-coolGray-900 bg-opacity-50" isOpen={props.isOpen} onClose={props.onClose}>
        <div className='fixed top-0 left-0 bottom-0 z-[100] w-full h-full bg-black/50'>
          <div className="fixed top-0 left-0 bottom-0 w-full w-4/6 max-w-xs bg-white">
            <nav className="relative p-6 h-full overflow-y-auto">
              <div className="flex flex-col justify-between h-full">
                <NavLink className="flex space-x-2 items-center py-2 font-bold" href="#">
                  <img src={basira_logo} alt="" className="xl:h-10 h-7 rounded-md bg-yellow-500" />
                  <div>freeislamicwill.ca</div>
                </NavLink>
                <ul className="py-6">
                  <li><NavLink onClick={props.onClose} className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md" to="/">Home</NavLink></li>
                  <li><NavLink onClick={props.onClose} className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md" to="faq">FAQ</NavLink></li>
                  <li><NavLink onClick={props.onClose} className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md" to="donate">Donate</NavLink></li>
                  <li><a className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md" href="/#endorsements">Endorsements</a></li>
                  <li><NavLink onClick={props.onClose} className="block py-3 px-4 text-coolGray-500 hover:text-coolGray-900 font-medium hover:bg-coolGray-50 rounded-md" to="contact">Contact</NavLink></li>
                </ul>
                <div className="flex flex-wrap">
                  <div className="w-half"><NavLink onClick={props.onClose} className="inline-block py-2 px-4 w-full text-sm leading-5 text-white bg-yellow-500 hover:bg-yellow-600 font-medium text-center focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 rounded-md" to="create">Create Will</NavLink></div> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <div className="w-half"><NavLink className="inline-block py-2 px-4 text-sm leading-5 text-yellow-50 bg-yellow-500 hover:bg-yellow-600 font-medium focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 rounded-md" to="https://freeislamicwill.ca/pdf/LastWillAndTestament_SAMPLE.pdf" target={"_blank"}>Sample Will</NavLink></div>
                </div>
              </div>
            </nav>
            <div className="navbar-close absolute top-5 p-4 right-3" onClick={props.onClose}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.94004 6L11.14 1.80667C11.2656 1.68113 11.3361 1.51087 11.3361 1.33333C11.3361 1.1558 11.2656 0.985537 11.14 0.860002C11.0145 0.734466 10.8442 0.66394 10.6667 0.66394C10.4892 0.66394 10.3189 0.734466 10.1934 0.860002L6.00004 5.06L1.80671 0.860002C1.68117 0.734466 1.51091 0.663941 1.33337 0.663941C1.15584 0.663941 0.985576 0.734466 0.860041 0.860002C0.734505 0.985537 0.66398 1.1558 0.66398 1.33333C0.66398 1.51087 0.734505 1.68113 0.860041 1.80667L5.06004 6L0.860041 10.1933C0.797555 10.2553 0.747959 10.329 0.714113 10.4103C0.680267 10.4915 0.662842 10.5787 0.662842 10.6667C0.662842 10.7547 0.680267 10.8418 0.714113 10.9231C0.747959 11.0043 0.797555 11.078 0.860041 11.14C0.922016 11.2025 0.99575 11.2521 1.07699 11.2859C1.15823 11.3198 1.24537 11.3372 1.33337 11.3372C1.42138 11.3372 1.50852 11.3198 1.58976 11.2859C1.671 11.2521 1.74473 11.2025 1.80671 11.14L6.00004 6.94L10.1934 11.14C10.2554 11.2025 10.3291 11.2521 10.4103 11.2859C10.4916 11.3198 10.5787 11.3372 10.6667 11.3372C10.7547 11.3372 10.8419 11.3198 10.9231 11.2859C11.0043 11.2521 11.0781 11.2025 11.14 11.14C11.2025 11.078 11.2521 11.0043 11.286 10.9231C11.3198 10.8418 11.3372 10.7547 11.3372 10.6667C11.3372 10.5787 11.3198 10.4915 11.286 10.4103C11.2521 10.329 11.2025 10.2553 11.14 10.1933L6.94004 6Z" fill="#556987"></path>
              </svg>
            </div>
          </div>
        </div>
      {/* <DrawerOverlay /> */}
      </Drawer>
    </>
    // <>
    //   <IconButton
    //     ref={btnRef}
    //     bg='primary'
    //     color='tertiary'
    //     justifyContent={'flex-end'}
    //     fontSize={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'sm' }}
    //     onClick={onOpen}
    //     icon={<HamburgerIcon />}
    //   />
    //   <Drawer
    //     isOpen={isOpen}
    //     placement='right'
    //     onClose={onClose}
    //     finalFocusRef={btnRef}>
    //     <DrawerOverlay />
    //     <DrawerContent bg='primary'>
    //       <DrawerCloseButton
    //         color='tertiary'
    //         variant='ghost'
    //       />
    //       <DrawerHeader color='tertiary'>
    //         <NavLink
    //           to='/'
    //           onClick={onClose}>
    //           <Image
    //             width={'15%'}
    //             src={source}
    //             objectFit='contain'
    //             alt='will document'
    //           />
    //         </NavLink>
    //       </DrawerHeader>

    //       <DrawerBody>
    //         <Navbar
    //           isMobile={true}
    //           onClose={onClose}
    //         />
    //       </DrawerBody>

    //       <DrawerFooter>
    //         <ButtonItem onClick={onClose}>Close</ButtonItem>
    //       </DrawerFooter>
    //     </DrawerContent>
    //   </Drawer>
    // </>
  );
}
