export const helperInfo = {
  executors: `
      <b>Who is the executor?</b>
      The term "executor" or "trustee" refers to the individual who has the duty of carrying out and managing the implementation of your wishes, as well as distributing your assets according to your Will or Wassiyah after your passing.
      \n\n<b>Who can be an executor</b>
        1. Executor has to be older than 18, honest, capable, who fully understands the importance of everything to go according to the Islamic law and is able and willing to accept the responsibility.
        2. It is highly recommended to have a backup executor just in case the main excutor can not do it.
        3. It is highly recommended that the executor is a practising Muslim or with knowledge of who to ask and where to go in regards to funeral and barriers etc..
        4. Death is a difficult time, choose an executor who will be able to act in the time of your death and don't burden your loved ones as it can be overwhelming.
        5. It is highly recommended that the executor is in the city you live in, otherwise it might not be practical.
        6. Your spouse or kid can be the excutor.
        \n\n<b>General points</b>
        1. It is recommended to inform the executor(s) that you put their name(s) in your Will/Wassiyah and inform them of the location of it. You can also send them a picture of the will.
        2. It is important for the executor to be up to date with your finances or at least knows how to reach to the list of your assets when needed. We are planning to work on a product to make this easier, but for now you can do that offline.
        `,
  guardians: `
      <b>Who is the guardian?</b>
      The person you appoint as the guardian in your will in Canada is someone who will take care of your minor children or dependents in the event of your death. This person will be responsible for making decisions regarding the care, education, and upbringing of your children until they reach the age of majority.
      \n\n<b>Is it necessary to name a guardian if my spouse is alive?</b>
      Absolutely. While your surviving spouse will typically assume custody of your children in the event of your passing, it's important to appoint a guardian in case both parents die simultaneously. If there is no will or if no guardian is designated in the will, the court will determine who should take care of your children. This could potentially result in your children living in a shelter or with someone who does not align with your wishes.

      \n\n<b>How to choose the guardian?</b>
      1. Guardian has to be someone trustworthy and who will take care of the kids deen.
      \n\n<b>General points</b>
          1. If you want your kids to be raised by your family overseas, put the guardian as someone local until your family can come to take the kids through court. If you did not do that, kids might be in foster homes until the court decides. Make sure to share your wishes with your named guardian.
          2. It is recommended to inform the guardian(s) that you put their name(s) in your Will/Wassiyah as guardian of your kids.
          3. It is highly recommended to add a backup guardian just in case the main excutor can not do it..
          `,
  charities: `
      <b>What is this section about?</b>
            Under Islamic law, it's permissible to allocate up to one-third of your estate as you see fit, subject to certain conditions (outlined below). This section enables you to designate a specific charity or individual to receive a portion of your estate.        
            \n\n<b>What are the rules I have to follow?</b>
            1. You cannot write the name of someone who is an inheritor according to Islamic law. You can use <u><a target="_blank" href="https://www.shariawiz.com/calculator/">this website</a></u> to know who are your current inheritors.
            2. Your total bequests can not exceed the third of your estate. So make sure the total number of percentages does not exceed 33.3%
            
            \n\n<b>How to find the charity number?</b>
            You can use <u><a target="_blank" href="https://apps.cra-arc.gc.ca/ebci/hacc/srch/pub/dsplyBscSrch">this website</a></u> to find the charity registration number and legal full name.
            Example of a charity number: "713882702 RR 0001" as found <u><a target="_blank" href="https://apps.cra-arc.gc.ca/ebci/hacc/srch/pub/dsplyRprtngPrd?q.srchNmFltr=basira&q.stts=0007&selectedCharityBn=713882702RR0001&dsrdPg=1">here</a></u>.
            \n\n<b>Can't find the charity number?</b>
            It's possible that the charity you're looking for isn't registered, which can impact tax efficiency. We strongly advise selecting a registered charity for optimal benefits. However, if you still prefer to support a non-registered charity, you can leave the charity number field blank.  
            \n\n<b>What to put in the purpose?</b>
            We highly recommened you include more than one purpose just in case the charity stopps providing the purpose you want to support.
            To find purposes to write go to your charity website and see what options they have under "donate".
            Example you can use: "building wills, taking care of orphans, digital islamic projects or building masjids."
            \n\n<b>General points</b>
            1. For tax efficiency, it is highly recommended to use local charities with charity status and not international or non-charity organizations.
            2. For local charities full legal name is enough, but adding the charity number is recommened.
        `,
  witnesses: `
        1. The two witnesses cannot be a beneficiary or a parent or a spouse of a beneficiary of your estate. This means the witnesses cannot be people who will receive something (i.e. a benefit) from your estate when you die.
        2. It is enough to have 2 witnesses. a 3rd is optional. 
        3. We highly recommend doing an affidavit as it will save you a lot of headache in case the will needs to go to court or in case something happens to the witnesses before you. (We will walk you through how to do it after finishing)
        4. The two witnesses must also sign the Will. All three (you and your two witnesses) must be together when signing.
        5. The executor named in the Will can be a witness as long as he or she is not also a beneficiary.
      `,
  backup: `
  <b>What is this section about?</b>
  This charity will act as a backup if for any reason there is some left over estate that cannot be distributed.
  For example if due to the demise or non-existence of an individual or charity specified in the previous section, this charity will serve as a contingency plan.
  \n\n<b>How to find the charity number?</b>
  You can use <u><a target="_blank" href="https://apps.cra-arc.gc.ca/ebci/hacc/srch/pub/dsplyBscSrch">this website</a></u> to find the charity registration number and legal full name.
  Example of a charity number: "713882702 RR 0001" as found <u><a target="_blank" href="https://apps.cra-arc.gc.ca/ebci/hacc/srch/pub/dsplyRprtngPrd?q.srchNmFltr=basira&q.stts=0007&selectedCharityBn=713882702RR0001&dsrdPg=1">here</a></u>.
  \n\n<b>Can't find the charity number?</b>
  It's possible that the charity you're looking for isn't registered, which can impact tax efficiency. We strongly advise selecting a registered charity for optimal benefits. However, if you still prefer to support a non-registered charity, you can leave the charity number field blank.  
  \n\n<b>What to put in the purpose?</b>
  We highly recommened you include more than one purpose just in case the charity stopps providing the purpose you want to support.
  To find purposes to write go to your charity website and see what options they have under "donate".
  Example you can use: "building wills, taking care of orphans, digital islamic projects or building masjids."
  `,
};
