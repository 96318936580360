import endorser_yahyam from "./images/endorser_yahya.jpeg";

export const endoresementInfo = [
  {
    name: 'Imam Yahya Momla',
    //src: 'https://media.licdn.com/dms/image/C5603AQGW6Cin6kApJQ/profile-displayphoto-shrink_800_800/0/1610487712583?e=2147483647&v=beta&t=FpBwXnx7xYLWZ2CkiRre0sBHC4qBHx1BTlYwKXl_Wa8',
    src: endorser_yahyam,
    position: `Imam at Masjid al Salaam, Burnaby BC`,
    testimony: `The brothers and sisters have done a great service to the Muslim community by creating this free resource. I hope this is the first step towards a robust system of Sharī'ah compliant estate management. I would recommend anyone who would like to create their will, to give freeislamicwill.ca a try.`,
  },
  // {
  //   name: 'Shaykh Fulan',
  //   src: 'https://picsum.photos/id/350/200',
  //   position: `Shaykh at
  //   Islamic Institution`,
  //   testimony: `FreeIslamicWill.ca is a game changer for Canadian Muslims. Not only does it help you fulfill the religious mandate of having a will readily available, its compliance with Canadian laws and since it's free, it makes it convenient for all Muslims to ensure their loved ones are cared for.`,
  // },
  // {
  //   name: 'Executor III',
  //   src: '',
  //   position: `Lorem ipsum dolor sit amet,\n consectetur adipiscing elit`,
  //   testimony: `Lorem Ipsum is unadulterated drivel. It means nothing whatsoever,
  //             but it's surprisingly useful. The main idea behind lorem ipsum is
  //             to have 'convincing' text, separated into words, paragraphs and
  //             with punctuation etc.Lorem Ipsum is unadulterated drivel. It means
  //             nothing whatsoever, but it's surprisingly useful. The main idea
  //             behind lorem ipsum is to have 'convincing' text, separated into
  //             words, paragraphs and with punctuation etc.Lorem Ipsum is
  //             unadulterated drivel. It means nothing whatsoever, but it's
  //             surprisingly useful. The main idea behind lorem ipsum is to have
  //             'convincing' text, separated into words, paragraphs and with
  //             punctuation etc.Lorem Ipsum is unadulterated drivel. It means
  //             nothing whatsoever, but it's surprisingly useful. The main idea
  //             behind lorem ipsum is to have 'convincing' text, separated into
  //             words, paragraphs and with punctuation etc.Lorem Ipsum is
  //             unadulterated drivel. It means nothing whatsoever, but it's
  //             surprisingly useful. The main idea behind lorem ipsum is to have
  //             'convincing' text, separated into words, paragraphs and with
  //             punctuation etc.`,
  // },
  // {
  //   name: 'Executor IV',
  //   src: '',
  //   position: `Lorem ipsum dolor sit amet,\n consectetur adipiscing elit`,
  //   testimony: `Lorem Ipsum is unadulterated drivel. It means nothing whatsoever,
  //             but it's surprisingly useful. The main idea behind lorem ipsum is
  //             to have 'convincing' text, separated into words, paragraphs and
  //             with punctuation etc.Lorem Ipsum is unadulterated drivel. It means
  //             nothing whatsoever, but it's surprisingly useful. The main idea
  //             behind lorem ipsum is to have 'convincing' text, separated into
  //             words, paragraphs and with punctuation etc.Lorem Ipsum is
  //             unadulterated drivel. It means nothing whatsoever, but it's
  //             surprisingly useful. The main idea behind lorem ipsum is to have
  //             'convincing' text, separated into words, paragraphs and with
  //             punctuation etc.Lorem Ipsum is unadulterated drivel. It means
  //             nothing whatsoever, but it's surprisingly useful. The main idea
  //             behind lorem ipsum is to have 'convincing' text, separated into
  //             words, paragraphs and with punctuation etc.Lorem Ipsum is
  //             unadulterated drivel. It means nothing whatsoever, but it's
  //             surprisingly useful. The main idea behind lorem ipsum is to have
  //             'convincing' text, separated into words, paragraphs and with
  //             punctuation etc.`,
  // },
];
