import React from 'react';
// import { Flex, Heading, useMediaQuery } from '@chakra-ui/react';

export default function PageWrapper({ heading, children }) {
  // const [isTinyMobile] = useMediaQuery(' (max-width: 350px)');
  return (
    <div
      className='flex min-h-[80vh] flex-col items-center rounded-md border border-yellow-300 m-0 px-2 sm:px-8 py-2 sm:py-4'
      // color='tertiary'
      // minH={'88.5vh'}
      // display='flex'
      // flexDirection='column'
      // alignItems='center'
      // borderColor={{ color: 'primaryTint' }}
      // borderWidth='5px'
      // m='0'
      // px={isTinyMobile ? '0.5em' : '2em'}
      // py={isTinyMobile ? '0.5em' : '1em'}
      >
      <h1
        className='mb-4 text-coolGray-400 text-sm md:text-md lg:text-lg'
        // mb={'1em'}
        // as='h1'
        // color='tertiary'
        // size={{ base: 'sm', sm: 'sm', md: 'md', lg: 'lg' }}
        >
        {heading}
      </h1>
      {children}
    </div>
  );
}
