import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import RootLayout from './components/RootLayout';

import { Home, Create, Faq, Donate, Endorsement, Contact, Pdf, Download, Update } from './pages/';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/'
      element={<RootLayout />}>
      <Route
        index
        element={<Home />}
      />
      <Route
        path='create'
        element={<Create />}
      />
      <Route
        path='update'
        element={<Update />}
      />
      <Route
        path='faq'
        element={<Faq />}
      />
      <Route
        path='donate'
        element={<Donate />}
      />
      {/* <Route
        path='endorsements'
        element={<Endorsement />}
      /> */}
      <Route
        path='contact'
        element={<Contact />}
      />
      <Route
        path='pdf'
        element={<Pdf />}
      />
      <Route
        path='download'
        element={<Download />}
      />
      {/* <Route path="profile" element={<Profile />} /> */}
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
