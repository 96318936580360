import { Button, Spinner } from "@chakra-ui/react";
import React, { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
//import TopHeading from './TopHeading';
import { useFormikContext } from "formik";
import { formValues } from "../components/Forms/formValues";
import WillDocument from "../components/PDF";
import { Link } from "react-router-dom";
import { CalendarIcon, DownloadIcon, InfoIcon } from "@chakra-ui/icons";
import { useToast, Tooltip } from "@chakra-ui/react"

// function PreviewFields({ data }) {
//   const displayValue = Array.isArray(data.value) ?
//     Object.values(data).some(obj => Object.values(obj).some(val => val !== '')) ?
//     <DisplayData data={data.value} /> :
//     <div className='text-slate-600'>None</div> :
//     data.value === undefined || data.value === null || data.value === '' ? <div className='text-slate-600'>None</div> :
//     <div className='text-slate-600'>{JSON.parse(JSON.stringify(data.value))}</div>

//   return (
//     <SimpleGrid pl={'1em'} columns={2} spacing={5}>
//       <Text className='w-full font-normal text-base md:text-lg mb-1 print:mb-0 print:text-base'>{data.subHeading}</Text>
//       <Box className='text-normal text-base md:text-lg'>{displayValue}</Box>
//     </SimpleGrid>
//   );
// }

// function DisplayData({ data }) {
//   const dataItems = data.map(item => (
//     <div key={item.name}>
//       <p><strong>Type:</strong> {item.type === undefined || item.type === null || item.type === '' ? <span className='text-slate-600'>None</span> : item.type}</p>
//       <p><strong>Name:</strong> {item.name === undefined || item.name === null || item.name === '' ? <span className='text-slate-600'>None</span> : item.name}</p>
//       <p><strong>Address:</strong> {item.address === undefined || item.address === null || item.address === '' ? <span className='text-slate-600'>None</span> : item.address}</p>
//       <p><strong>Percentage:</strong> {item.percentage === undefined || item.percentage === null || item.percentage === '' ? <span className='text-slate-600'>None</span> : item.percentage + "%"}</p>
//     </div>
//   ));

//   return (
//     <div>{dataItems}</div>
//   );
// }

// function PreviewPDF({ data }) {
//   // const heading = data.heading;
//   // const isSubheading = data.isSubheading;
//   // const dataEntries = Object.entries(data.data);

//   console.log("data here: ", data  );
//   // store your data in a session or local storage
//   // Why don't we use "state" and pass it down as props?
//   sessionStorage.setItem('data', JSON.stringify(data));

//   return (
//     <>
//       <PDFViewer height={window.innerHeight} width="100%">
//         <WillDocument />
//       </PDFViewer>
//     </>
//   );
// }

function sortValues(values) {
  Object.keys(values).forEach((key) => {
    const main = key.substring(0, key.indexOf("_"));
    const sub = key.substring(key.indexOf("_") + 1, key.lastIndexOf("_"));

    if (formValues[main].isSubheading) {
      formValues[main].data[sub].value[key].value = values[key];
    } else {
      formValues[main].data[key].value = values[key];
    }
  });
}

function saveEmailAddress(values) {
  //Call lambda function to save user's email address
  fetch(
    "https://oub3y0a0ab.execute-api.ca-central-1.amazonaws.com/default/islamicWillUserInsertFunction",
    {
      method: "POST",
      headers: {
        Accept: "*/*",
        // Content-Type: 'application/json', // <-- does not work with APIGW/lambda for some reason
        "Content-Type": "text/plain",
      },
      body: JSON.stringify({
        user_email: values.personal_email,
      }),
    }
  );
  sessionStorage.setItem("data", "");
}

const backupJson = () => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(JSON.parse(localStorage.getItem("formData")))
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = `BackupWillInformation-${new Date().toLocaleString("en-US")}.json`;
  link.click();
  localStorage.removeItem("formData")
};

export default function Download() {
  const [isSent, setIsSent] = useState(false);
  const [isInstructionsDownloaded, setIsInstructionsDownloaded] = useState(false);
  const [isWillDownloaded, setWillDownloaded] = useState(false);
  const { values } = useFormikContext();
  sortValues(values);
  sessionStorage.setItem("data", JSON.stringify(values));
  const toast = useToast()

  return (
    <div class="container">
      {/* <div class="flex flex-wrap xl:items-center -mx-4">
        <div class="w-full md:w-1/2 px-4 mb-16 md:mb-0"> */}
          <div class="divCallToDonate">
            <p>Your will is almost ready!</p>
            <span>
              1. Click on "Download Instructions" button to download the
              instructions tailored to your province. <br />
              <br />
              2. Click on "Download your will" button to download your
              personalized will. (button will appear only after you download the
              instructions.) <br />
              <br />
              3. Follow the instructions to complete your will. <br />
              <br />
              4. Your will is ready! <br />
              <br />
              5. Backup your information. <br />
              <br />
              You can reach out to us at freeislamicwill@basira.ca. Please keep
              in mind that we are not legal professionals and will not be able
              to answer any legal inquiries.
            </span>
            {/* <span>Your will is 100% free!</span>
                <span>For supporting this project and other projects like this, please consider making a donation.</span> */}
          </div>
          <br />
          <hr class="thin-line" />

          <div class="button-row">
            {!isWillDownloaded && (
            <Tooltip label='Click here to download instructions' placement='top' isDisabled>
              {values.personal_province === "British Columbia" ? (
                <Link
                  to="https://freeislamicwill.ca/pdf/instructions_BC.pdf"
                  class="rounded-button"
                  target="_blank"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    toast({
                      title: 'Next Click on Download your will',
                      status: 'info',
                      isClosable: true,
                      duration: 3000,
                      position: 'top'
                    })
                    setIsInstructionsDownloaded(true);
                  }}
                  download
                >
                  <InfoIcon />
                  &nbsp;Download Instructions
                </Link>
              ) : values.personal_province === "Ontario" ? (
                <Link
                  to="https://freeislamicwill.ca/pdf/instructions_ON.pdf"
                  class="rounded-button"
                  target="_blank"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    toast({
                      title: 'Next Click on Download your will',
                      status: 'info',
                      isClosable: true,
                      duration: 3000,
                      position: 'top'
                    })
                    setIsInstructionsDownloaded(true);
                  }}
                  download
                >
                  <InfoIcon />
                  &nbsp;Download Instructions
                </Link>
              ) : (
                <Link
                  to="https://freeislamicwill.ca/pdf/instructions_AB.pdf"
                  class="rounded-button"
                  target="_blank"
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    toast({
                      title: 'Next Click on Download your will',
                      status: 'info',
                      isClosable: true,
                      duration: 3000,
                      position: 'top'
                    })
                    setIsInstructionsDownloaded(true);
                  }}
                  download
                >
                  <InfoIcon />
                  &nbsp;Download Instructions
                </Link>
              )}
            </Tooltip>
            )}
            {isInstructionsDownloaded && (
              <PDFDownloadLink
                document={<WillDocument data={values} />}
                fileName={`LastWillAndTestament-${new Date().toLocaleString("en-US")}`}
              >
                {({ loading }) =>
                  loading ? (
                    <Button class="rounded-button" style={{ marginTop: "5px" }}>
                      <Spinner
                        thickness="1px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="black.500"
                        size="sm"
                      />{" "}
                      Loading Document...
                    </Button>
                  ) : (
                    <Tooltip label='Click here to download your will!' placement='top' closeDelay={1000}>
                      <Button
                        class="rounded-button"
                        style={{ marginTop: "5px" }}
                        onClick={(e) => {
                          setWillDownloaded(true)
                          if (!isSent) {
                            toast({
                              title: 'Your will and testament is ready to go! Do not forget to download your backup information below so you can easily update.',
                              status: 'success',
                              isClosable: true,
                              duration: 30000,
                              position: 'top'
                            })
                            toast({
                              title: 'A small doantion of $10 will support this project and build other free digital projects that benefits Muslims.',
                              status: 'info',
                              isClosable: true,
                              duration: 30000,
                              position: 'top'
                            })
                            setIsSent(true);
                            saveEmailAddress(values);
                          }
                        }}
                      >
                        <DownloadIcon />
                        &nbsp;Download your will
                      </Button>
                    </Tooltip>
                  )
                }
              </PDFDownloadLink>
            )}
            {isWillDownloaded && (
            <Tooltip label='Click here to backup your information' placement='top' closeDelay={1000}>
                <Button
                  class="rounded-button"
                  style={{ marginTop: "5px" }}
                  onClick={(e) => {
                      toast({
                        title: 'Your information downloaded successfully, you can use this to update your will in future. Keep your information in secure location',
                        status: 'success',
                        isClosable: true,
                        duration: 30000,
                        position: 'top'
                      })
                    backupJson()
                  }}
                >
                  <CalendarIcon />
                  &nbsp;Backup your info
                </Button>
            </Tooltip>
            )}
          </div>
        {/* </div> */}
        {/* <div class="w-full md:w-1/2 px-4 mb-16 md:mb-0"> */}
          {isInstructionsDownloaded && (
            <div>
              <div>&nbsp;</div>
              <div class="divCallToDonate">
                <p>Make a Difference Today!</p>
                <span>Your will is 100% free!</span>
                <span>
                  For supporting this project and other projects like this,
                  please consider making a donation.
                </span>
                <Link
                  to="/Donate"
                  //class="rounded-button"
                  class="rounded-button text-white mt-3 flex w-full items-center justify-center rounded-md bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-300 hover:to-blue-400"
                  target="_blank"
                  style={{ marginTop: "5px" }}
                > <b>Donate Now {">>"}</b>
                </Link>
              </div>

              {/* <div class="zeffyContainerDiv">
                <iframe
                  title="Donation form powered by Zeffy"
                  src="https://www.zeffy.com/en-CA/embed/donation-form/ab0a9b1e-aa78-409d-8da3-946a36d5dca2"
                  allowpaymentrequest
                  allowTransparency="true"
                ></iframe>
              </div> */}
            </div>
          )}
        {/* </div> */}
      {/* </div> */}
      {/*       
      
      {<div>&nbsp;</div>}
      {<hr class="thin-line"/>} */}
    </div>
  );
}
