import React from 'react';
import { Button } from '@chakra-ui/react';

export default function ButtonItem({
  onClick,
  isDisabled,
  isHidden,
  variant,
  color,
  bg,
  children,
  ...props
}) {
  return (
    <Button className={`{font-thin text-sm md:text-base}`} style={{display: isHidden ? 'none' : 'block'}}
      fontWeight={'thin'}
      size='md'
      // fontSize={{ base: 'xs', sm: 'xs', md: 'xs', lg: 'sm' }}
      onClick={onClick}
      variant={variant ? variant : 'solid'}
      isDisabled={isDisabled ? isDisabled : false}
      // _hover={{ bg: bg ? bg : 'tertiary' }}
      // bg={bg ? bg : 'tertiary'}
      // color={color ? color : 'primary'}
      //w='5rem'
      {...props}>
      {children}
      
    </Button>
  );
}
