import React from 'react';
import { NameAddress, TopHeading } from '.';

export default function Guardians({ toolRef }) {
  return (
    <>
      <TopHeading
        heading='Guardians (Optional)'
        toolRef={toolRef}
      />
      <NameAddress
        mainName={'guardian_main'}
        heading='Main Guardian'
        isAddressVisible={true}
      />
      <NameAddress
        mainName={'guardian_backup'}
        heading='Backup Guardian'
        isAddressVisible={true}
      />
    </>
  );
}
