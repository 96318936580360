import React, { useEffect } from 'react';
// import { EditIcon } from '@chakra-ui/icons';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import {
  useMediaQuery,
  Box,
  Flex,
  // Button,
  Spacer,
  // Heading,
  Image,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import source from '../assets/scrolls.png';
import circle3Red from '../assets/circle3-red.svg';
import dots3Green from '../assets/dots3-green.svg';
import headerImage from '../assets/header-new.svg';
import { CardSwiper } from '../components/Cards';

import Footer from "../components/Footer";
import HowItWorks from "../components/HowItWorks";

export default function Home() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  // useEffect(() => {
  //   document.querySelector(`#endorsements`).scrollIntoView()
  // }, [])
  return (
    <div className="pt-20 pb-5 md:pt-28">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap xl:items-center -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-16 md:mb-0">
            <span className="inline-block py-px px-2 mb-4 text-xs leading-5 text-white bg-yellow-500 uppercase rounded-3xl">Free Forever</span>
            <h1 className="mb-6 text-3xl md:text-5xl lg:text-6xl leading-tight font-bold tracking-tight">Free Islamic Wills in Canada</h1>
            <p className="mb-8 text-lg md:text-xl text-coolGray-500 font-medium">Create yours in just a few clicks.</p>
            <p className="mb-8 text-lg md:text-xl text-coolGray-500 font-medium">We care about your privacy: your data never leaves your machine. Everything is computed on your browser!</p>
            <div className="flex flex-wrap">
              <div className="w-full md:w-auto py-1 md:py-0 md:mr-4"><NavLink className="inline-block py-4 px-7 w-full text-base md:text-lg leading-4 text-yellow-50 font-medium text-center bg-yellow-500 hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 border border-yellow-500 rounded-md shadow-sm" to="create">Create your Will today!</NavLink></div>
              <div className="w-full md:w-auto py-1 md:py-0"><NavLink className="inline-block py-4 px-7 w-full text-base md:text-lg leading-4 text-coolGray-800 font-medium text-center bg-white hover:bg-coolGray-100 focus:ring-2 focus:ring-coolGray-200 focus:ring-opacity-50 border border-coolGray-200 rounded-md shadow-sm" to="donate">Support Us</NavLink></div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4 md:px-20">
            <div className="relative mx-auto md:mr-0 max-w-max">
              <Image className="absolute z-10 -left-14 -top-12 w-28 md:w-auto" src={circle3Red} alt="islamicwill.ca" />
              <Image className="absolute z-10 -right-7 -bottom-8 w-28 md:w-auto" src={dots3Green} alt="islamicwill.ca" />
              <Image className="relative rounded-7xl" src={headerImage} alt="islamicwill.ca" />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24"></div>
      
      <HowItWorks />
      <CardSwiper  />

      <Footer />
    </div>
    // <div className='flex flex-col text-black'>
    //   <Flex className="min-h-[75vh] flex-col-reverse md:flex-row max-w-4xl mx-auto justify-center items-center gap-0 md:gap-3">
    //     <Flex className='flex flex-col justify-center md:justify-start gap-5'>
    //       <Box align={isMobile ? 'center' : 'flex-start'}>
    //         <h1 className='text-lg md:text-2xl lg:text-6xl max-w-xl font-extrabold'>
    //           Free Islamic Wills in Canada
    //         </h1>
    //         <h1 className='pt-2 text-lg font-normal'>
    //           Create yours in just a few clicks
    //         </h1>
    //       </Box>
    //       <Spacer gap='5' />
    //       <NavLink to='/create'>
    //         <button className='bg-orange-400 text-organge-50 text-base md:text-xl w-max px-8 flex py-3 rounded-xl font-thin hover:bg-orange-500 items-center '>
    //           <div className='mr-2 text-orange-50'>
    //             Create a will
    //           </div>
    //           <ArrowRightIcon className='h-6 w-6 text-orange-50' />
    //         </button>
    //       </NavLink>
    //     </Flex>
    //     {!isMobile && <Spacer />}
    //     <Image
    //       boxSize={isMobile ? '35%' : '40%'}
    //       src={source}
    //       alt='a will'
    //       objectFit='contain'
    //     />
    //     </Flex>
    //   <CardSwiper />
    // </div>
  );
}
